import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { Paper, Typography, Box } from "@mui/material";
// import Draggable from 'react-draggable'
import { CustomButtom } from "./CustomButton";

// function PaperComponent(props) {
//   return (
//     <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
//       <Paper {...props} />
//     </Draggable>
//   )
// }

const DefaultHeader = ({ text }) => (
  <DialogTitle sx={{ m: 0, p: 2 }} id="draggable-dialog-title">
    {text}
  </DialogTitle>
);

const Model = ({
  children,
  open = false,
  header = false,
  headerText,
  footer,
  close = false,
  handleSubmit = () => {},
  handleClose = () => {},
  loading = false,
  resetForm = () => {},
  checked = null,
  customWidth = false,
}) => {
  React.useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        fullWidth
        // PaperComponent={PaperComponent}
        open={open}
        aria-labelledby="draggable-dialog-title"
        sx={
          customWidth
            ? {
                "& .MuiDialog-paper": {
                  maxWidth: "60%",
                },
              }
            : {}
        }
      >
        <Box>
          {header && (
            <DialogTitle sx={{ fontWeight: "bold" }}>{headerText}</DialogTitle>
          )}

          {close ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
        <form noValidate onSubmit={handleSubmit}>
          <DialogContent dividers>{children}</DialogContent>
          <DialogActions
            style={{ marginRight: "4%" }}
            className="modalform-footer"
          >
            {footer && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Typography ml={3} color={"gray"}>
                  * Indicates required
                </Typography>

                <div>
                  <Button autoFocus onClick={handleClose}>
                    Cancel
                  </Button>
                  <CustomButtom
                    text={"Save"}
                    loading={loading}
                    disabled={(checked !== null ? !checked : false) || loading}
                    variant="contained"
                    type="submit"
                    size="medium"
                  />
                </div>
              </div>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default Model;

Model.propTypes = {
  header: PropTypes.bool,
  headerText: PropTypes.string,
  footer: PropTypes.bool,
  close: PropTypes.bool,
  checked: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
};
