import { useState } from "react";
import {
  AppBar,
  Avatar,
  Button,
  Divider,
  IconButton,
  Popover,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItemText,
  ListItemButton,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

export const Navigation = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const open = Boolean(anchorEl);
  const userDetails = JSON.parse(localStorage.getItem("user"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
    localStorage.removeItem("user");
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  // Custom active link styling
  const getButtonStyles = (path) => {
    return {
      backgroundColor:
        location.pathname === path ? "rgba(0, 0, 0, 0.1)" : "transparent",
      fontWeight: location.pathname === path ? "bold" : "normal",
      position: "relative",
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        width: location.pathname === path ? "100%" : 0,
        height: "2px",
        backgroundColor: theme.palette.primary.main,
        transition: "width 0.3s ease",
      },
      "&:hover::after": {
        width: "100%",
      },
      // // backgroundColor:
      // //   location.pathname === path ? "rgba(0, 0, 0, 0.1)" : "transparent",
      // // fontWeight: location.pathname === path ? "bold" : "normal",
      // position: "relative",
      // "&::after": {
      //   content: '""',
      //   position: "absolute",
      //   bottom: 0,
      //   left: 0,
      //   width: location.pathname === path ? "100%" : 0,
      //   // height: "2px",
      //   // backgroundColor: theme.palette.primary.main,
      //   border: location.pathname === path ? '1px solid #fff' : '',
      //   transition: "width 0.3s ease",
      // },
      // "&:hover::after": {
      //   width: "100%",
      // },
    };
  };

  const NavigationLinks = ({ inDrawer = false }) => {
    const ButtonComponent = inDrawer ? ListItemButton : Button;
    const buttonProps = inDrawer ? {} : { color: "inherit" };

    return user ? (
      <>
        <ButtonComponent
          {...buttonProps}
          sx={!inDrawer ? getButtonStyles("/dashboard") : {}}
          onClick={() => handleNavigation("/dashboard")}
        >
          {inDrawer ? <ListItemText primary="Dashboard" /> : "Dashboard"}
        </ButtonComponent>

        {user.roles.includes("admin") && (
          <>
            <ButtonComponent
              {...buttonProps}
              sx={!inDrawer ? getButtonStyles("/admin") : {}}
              onClick={() => handleNavigation("/admin")}
            >
              {inDrawer ? <ListItemText primary="Admin" /> : "Admin"}
            </ButtonComponent>
            <ButtonComponent
              {...buttonProps}
              sx={!inDrawer ? getButtonStyles("/my-surveys") : {}}
              onClick={() => handleNavigation("/my-surveys")}
            >
              {inDrawer ? <ListItemText primary="My Surveys" /> : "My Surveys"}
            </ButtonComponent>
            <ButtonComponent
              {...buttonProps}
              sx={!inDrawer ? getButtonStyles("/result-analytics") : {}}
              onClick={() => handleNavigation("/result-analytics")}
            >
              {inDrawer ? (
                <ListItemText primary="Result Analytics" />
              ) : (
                "Result Analytics"
              )}
            </ButtonComponent>
          </>
        )}

        {user.roles.includes("user") && (
          <ButtonComponent
            {...buttonProps}
            sx={!inDrawer ? getButtonStyles("/survey-responses") : {}}
            onClick={() => handleNavigation("/survey-responses")}
          >
            {inDrawer ? <ListItemText primary="Responses" /> : "Responses"}
          </ButtonComponent>
        )}
      </>
    ) : (
      <ButtonComponent
        {...buttonProps}
        sx={!inDrawer ? getButtonStyles("/login") : {}}
        onClick={() => handleNavigation("/login")}
      >
        {inDrawer ? <ListItemText primary="Login" /> : "Login"}
      </ButtonComponent>
    );
  };

  return (
    <AppBar
      position="sticky"
      // sx={{
      //   zIndex: (theme) => theme.zIndex.drawer + 1,
      // }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"start"}
          gap={1}
        >
          {isMobile && user && user?.roles?.[0] !== "guest" && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: "pointer", color: "#fff" }}
            onClick={() => handleNavigation("/dashboard")}
          >
            Smart Survey
          </Typography>
        </Box>

        {/* Desktop Navigation */}
        <Box>
          {!isMobile && user && user?.roles?.[0] !== "guest" && (
            <NavigationLinks />
          )}

          {/* User Avatar and Menu */}
          {user && user?.roles?.[0] !== "guest" && (
            <>
              <Button
                onClick={handleClick}
                sx={{
                  minWidth: "auto",
                  borderRadius: "50%",
                }}
              >
                <Avatar
                  alt={`${userDetails?.firstName} ${userDetails?.lastName}`}
                  src=""
                  sx={{
                    backgroundColor: "#b8c1ec",
                    color: "#232946",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  <Typography variant="button text">
                    {`${userDetails?.firstName?.split(" ")[0][0]}${
                      userDetails?.lastName?.split(" ")[0][0]
                    }`}
                  </Typography>
                </Avatar>
              </Button>

              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box sx={{ p: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      Account
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#A3A3A3" }}>
                      {`Welcome, ${userDetails?.firstName} ${userDetails?.lastName}!`}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#A3A3A3" }}>
                      {userDetails?.email}
                    </Typography>
                  </Box>
                  <Divider sx={{ mb: 1 }} />
                  <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      handleClose();
                      handleLogout();
                    }}
                  >
                    Logout
                  </Button>
                </Box>
              </Popover>
            </>
          )}
        </Box>

        {/* Mobile Navigation Drawer */}
        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
          <Box sx={{ width: 250 }}>
            {/* Drawer Header with Close Button */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2,
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <Typography variant="h6">Menu</Typography>
              <IconButton onClick={handleDrawerToggle}>
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Navigation Links */}
            <List>
              <NavigationLinks inDrawer={true} />
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};
