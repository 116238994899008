import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import { Container } from "@mui/system";
const LoginPage = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [credentials, setCredentials] = useState({ email: "", password: "" });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Mock login - replace with actual API call
    const mockUser = {
      id: 1,
      email: credentials.email,
      firstName: "John",
      lastName: "Doe",
      roles: ["admin"],
    };
    login(mockUser);
    navigate("/dashboard");
  };

  return (
    <Container
      maxWidth={false}
      sx={
        {
          // height: "100vh",
          // width: "100%",
          // backgroundImage:
          //   "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url('/assets/survey-form-image.jpg')",
          // backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",
          // backgroundPosition: "center",
        }
      }
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Paper elevation={3} sx={{ p: 4, maxWidth: 400, mt: 10 }}>
          <Typography variant="h5" gutterBottom>
            Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              type="email"
              value={credentials.email}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setCredentials({ ...credentials, email: e.target.value })
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              type="password"
              value={credentials.password}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setCredentials({ ...credentials, password: e.target.value })
              }
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mt: 2 }}
            >
              Login
            </Button>
          </form>

          <Divider sx={{ my: 3 }} />

          <Typography variant="body2" align="center" color="text.secondary">
            Create an account?{" "}
            <Button color="primary" onClick={() => navigate("/signup")}>
              Sign up
            </Button>
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default LoginPage;
