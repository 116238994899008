import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  Button,
  Chip,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import NormalCell from "../TableComponents/TableCells/NormalCell";
import DateCell from "../TableComponents/TableCells/DateCell";
import StatusCell from "../TableComponents/TableCells/StatusCell";
import ProgressCell from "../TableComponents/TableCells/ProgressCell";
import { CustomTable } from "../TableComponents/CustomTable";
import {
  Leaderboard as LeaderboardIcon,
  ViewList as ViewListIcon,
  CalendarMonth as CalendarMonthIcon,
} from "@mui/icons-material";
import ActionButtonCell from "../TableComponents/TableCells/ActionButtonCell";
import NameCell from "../TableComponents/TableCells/NameCell";
import EmailPhoneCell from "../TableComponents/TableCells/EmailPhoneCell";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

const formatDate = (date) => {
  return format(new Date(date), "dd-MMM-yyyy");
};

const ResponseCollection = () => {
  const { surveyId } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [responses, setResponses] = useState([]);
  const [gender, setGender] = useState([]);
  const [filteredResponses, setFilteredResponses] = useState([]);
  const [statusFilter, setStatusFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedGender, setSelectedGender] = useState("all");

  useEffect(() => {
    const mockResponses = [
      {
        surveyId: 1,
        surveyTitle: "Customer Feedback Survey",
        description:
          "A survey to collect customer feedback on our products. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe sint pariatur ipsum, quam, blanditiis quod voluptates sapiente laudantium iure reiciendis placeat incidunt hic nisi fuga vitae tempore repudiandae doloremque repellendus.",
        published_date: "2024-11-25",
        updated_date: "2024-11-30",
        started_on: "",
        ends_on: "",
        is_anonymous: false,
        respondents: [
          {
            id: 1,
            respondent: "John Doe",
            email: "johndoe@email.com",
            date: "2024-11-22",
            status: "complete",
            gender: "Male",
            timeSpent: "12m 30s",
            ip_address: "192.168.0.1",
            phone_no: "9296562248",
            progress: 100,
            lastActivity: "2024-11-22 14:30",
          },
          {
            id: 2,
            respondent: "Martin Garrix",
            email: "martin@email.com",
            date: "2024-11-28",
            status: "pending",
            gender: "Male",
            timeSpent: "5m 45s",
            ip_address: "192.168.10.1",
            phone_no: "9868122354",
            progress: 50,
            lastActivity: "2024-11-28 15:45",
          },
          {
            id: 3,
            respondent: "Natasha Blix",
            email: "natasha@email.com",
            date: "2024-10-08",
            status: "partial",
            gender: "Female",
            timeSpent: "7m 45s",
            ip_address: "192.168.100.1",
            phone_no: "",
            progress: 80,
            lastActivity: "2024-10-08 13:45",
          },
        ],
      },
      {
        surveyId: 4,
        surveyTitle: "Product Feedback Survey",
        description: "A survey to gather feedback on a new product release.",
        published_date: "2024-10-28",
        updated_date: "2024-11-02",
        started_on: "",
        ends_on: "",
        is_anonymous: true,
        respondents: [
          {
            id: 1,
            respondent: "Anonymous",
            email: "",
            date: "2024-11-21",
            status: "partial",
            gender: "",
            timeSpent: "5m 45s",
            ip_address: "",
            phone_no: "",
            progress: 60,
            lastActivity: "2024-11-21 16:45",
          },
          {
            id: 2,
            respondent: "Anonymous",
            email: "",
            date: "2024-10-20",
            status: "complete",
            gender: "",
            timeSpent: "8m 45s",
            ip_address: "",
            phone_no: "",
            progress: 100,
            lastActivity: "2024-10-20 18:45",
          },
          {
            id: 3,
            respondent: "Anonymous",
            email: "",
            date: "2024-10-25",
            status: "pending",
            gender: "",
            timeSpent: "2m 45s",
            ip_address: "",
            phone_no: "",
            progress: 10,
            lastActivity: "2024-10-25 13:45",
          },
        ],
      },
    ];

    const decodedString = atob(surveyId).split("_")[0];
    const filteredData = mockResponses.filter(
      (item) => item.surveyId === Number(decodedString)
    )[0];
    setResponses(filteredData);
    setFilteredResponses(filteredData);
  }, [surveyId]);

  useEffect(() => {
    let filtered = responses?.respondents;
    // Apply status filter
    if (statusFilter !== "all") {
      filtered = filtered?.filter(
        (response) => response?.status === statusFilter
      );
    }

    // Apply survey filter
    if (selectedGender !== "all") {
      filtered = filtered?.filter(
        (response) => response?.gender === selectedGender
      );
    }

    // Apply search filter
    if (searchQuery) {
      filtered = filtered?.filter(
        (response) =>
          response?.respondent
            .toLowerCase()
            .includes(searchQuery?.toLowerCase()) ||
          response?.email.toLowerCase().includes(searchQuery?.toLowerCase())
      );
    }

    setFilteredResponses(filtered);
    setPage(0);
  }, [statusFilter, searchQuery, selectedGender, responses]);

  useEffect(() => {
    // const uniqueSurveyTitles = responses
    //   ?.map((item) => item.surveyTitle)
    //   .filter((title, index, self) => self.indexOf(title) === index);

    setGender(["Male", "Female", "Others"]);
  }, [responses]);

  const getHeaders = (isAnonymous) => {
    const allHeaders = [
      {
        name: "serial_no",
        displayName: "S.No",
        width: `${100 / 11}%`,
        cell: ({ row, index }) => <NormalCell row={{ ...row, text: index + 1 }} />,
      },
      {
        name: "respondent",
        displayName: "Respondent",
        width: `${100 / 11}%`,
        cell: ({ row }) => <NameCell row={{ ...row }} />,
      },
      {
        name: "ip_address",
        displayName: "IP Address",
        width: `${100 / 11}%`,
        cell: ({ row }) => (
          <NormalCell row={{ ...row, text: row.ip_address }} />
        ),
      },
      {
        name: "gender",
        displayName: "Gender",
        width: `${100 / 11}%`,
        cell: ({ row }) => <NormalCell row={{ ...row, text: row.gender }} />,
      },
      {
        name: "email-phone",
        displayName: "Email/Phone",
        width: `${100 / 11}%`,
        cell: ({ row }) => <EmailPhoneCell row={{ ...row }} />,
      },
      {
        name: "date",
        displayName: "Date",
        width: isAnonymous ? `${100 / 7}%` : `${100 / 11}%`,
        cell: ({ row }) => <DateCell row={{ ...row, text: row.date }} />,
      },
      {
        name: "timeSpent",
        displayName: "Time Spent",
        width: isAnonymous ? `${100 / 7}%` : `${100 / 11}%`,
        cell: ({ row }) => <NormalCell row={{ ...row, text: row.timeSpent }} />,
      },
      {
        name: "Progress",
        displayName: "Progress",
        width: isAnonymous ? `${100 / 7}%` : `${100 / 11}%`,
        cell: ({ row }) => <ProgressCell row={{ ...row }} />,
      },
      {
        name: "status",
        displayName: "Status",
        width: isAnonymous ? `${100 / 7}%` : `${100 / 11}%`,
        cell: ({ row }) => <StatusCell row={{ ...row, text: row.status }} />,
      },
      {
        name: "lastActivity",
        displayName: "Last Activity",
        width: isAnonymous ? `${100 / 7}%` : `${100 / 11}%`,
        cell: ({ row }) => (
          <NormalCell row={{ ...row, text: row.lastActivity }} />
        ),
      },
      {
        name: "action",
        displayName: "Action",
        width: isAnonymous ? `${100 / 7}%` : `${100 / 11}%`,
        cell: ({ row }) => (
          <ActionButtonCell
            row={{ row }}
            buttonIcon={<ViewListIcon fontSize="small" />}
            buttonLabel={"View Details"}
          />
        ),
      },
    ];

    const anonymousHeaders = [...allHeaders.slice(0,1), ...allHeaders.slice(5)]

    return isAnonymous ? anonymousHeaders : allHeaders;
  };

  const headers = getHeaders(responses?.is_anonymous);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container maxWidth="fuild" sx={{ mt: 6 }}>
      <Box sx={{ mb: 4, px: 4 }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Box>
            <Typography variant="h4">
              <strong>Survey Responses: </strong>
              {responses?.surveyTitle}
              {responses?.is_anonymous && (
                <Chip
                  label="Anonymous"
                  size="small"
                  sx={{
                    ml: 1,
                    backgroundColor: "purple",
                    color: "#fff",
                    fontSize: "1rem",
                  }}
                />
              )}
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{ fontSize: 11 }}
            startIcon={<LeaderboardIcon fontSize="small" />}
          >
            View Analysis
          </Button>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1">
            <strong>Description:</strong> {responses?.description}
          </Typography>
          <Stack direction={"row"} alignItems={"center"} spacing={0.6}>
            <CalendarMonthIcon style={{ width: "16px", height: "16px" }} />
            <Typography variant="subtitle1">
              {responses?.updated_date ? (
                <>
                  <strong>Last Updated Date:</strong>{" "}
                  {responses?.updated_date
                    ? formatDate(responses?.updated_date)
                    : "--"}
                </>
              ) : (
                <>
                  <strong>Published Date:</strong>{" "}
                  {responses?.published_date
                    ? formatDate(responses?.published_date)
                    : "--"}
                </>
              )}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle1">
              <strong>Started On:</strong>{" "}
              {responses?.started_on
                ? formatDate(responses?.started_on)
                : responses?.published_date &&
                  formatDate(responses?.published_date)}
            </Typography>
            {responses?.ends_on && (
              <Typography variant="subtitle1">
                <strong>Ends On:</strong>{" "}
                {responses?.ends_on && formatDate(responses?.ends_on)}
              </Typography>
            )}
          </Stack>
          <Typography variant="subtitle1">
            <strong>Total Responses: </strong>
            {responses?.respondents ? responses?.respondents?.length : 0}
          </Typography>
        </Box>

        {/* Filters */}
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={responses?.is_anonymous ? 6 : 4}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by respondent or email"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {!responses?.is_anonymous && (
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Gender Filter</InputLabel>
                <Select
                  value={selectedGender}
                  label="Gender Filter"
                  onChange={(e) => setSelectedGender(e.target.value)}
                >
                  <MenuItem value="all">All</MenuItem>
                  {gender?.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} md={responses?.is_anonymous ? 6 : 4}>
            <FormControl fullWidth>
              <InputLabel>Status Filter</InputLabel>
              <Select
                value={statusFilter}
                label="Status Filter"
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <MenuItem value="all">All Responses</MenuItem>
                <MenuItem value="complete">Complete</MenuItem>
                <MenuItem value="partial">Partial</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <CustomTable
          count={filteredResponses?.length}
          headers={headers}
          rows={filteredResponses}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          listLoading={loading}
          emptyRecordText={"No record has been found."}
        />
      </Box>
    </Container>
  );
};

export default ResponseCollection;
