import React from "react";
import SurveyAnalysis from "../components/survey/SurveyAnalysis";
import { Container } from "@mui/system";

const initialData = [
  {
    surveyId: 1,
    title: "Customer Support Experience Survey",
    status: "published",
    description: "A survey to evaluate customer support interactions.",
    created_on: "2024-10-10",
    questions: [
      {
        id: "q1",
        question: "What is your favorite color?",
        type: "multiple-choice",
        responses: ["Blue", "Red", "Blue", "Green", "Red", "Purple"],
      },
      {
        id: "q2",
        question: "What do you like about our service?",
        type: "multiple-choice",
        responses: ["Support", "Delivery", "Pricing", "Support", "Experience"],
      },
      {
        id: "q3",
        question: "Rate our service (1-5)",
        type: "rating",
        responses: [5, 4, 3, 4, 5, 2, 5, 4],
      },
      {
        id: "q4",
        question: "Rate our customer support (1-5)",
        type: "rating",
        responses: [4, 5, 3, 4, 5, 5, 2, 3],
      },
    ],
  },
];

const SurveyResponseAnalysis = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 6 }}>
      <SurveyAnalysis surveyData={initialData} />
    </Container>
  );
};

export default SurveyResponseAnalysis;
