import { Container, Grid } from "@mui/material";
import UserManagement from "../components/dashboard/UserManagement";

const AdminPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} >
          <UserManagement />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminPage;
