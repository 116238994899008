import { Typography } from "@mui/material";
import React from "react";

const EmailPhoneCell = ({ row }) => {
  return (
    <>
      {row?.email || row?.phone_no ? (
        <>
          {row?.email && <Typography fontSize="15px">{row.email}</Typography>}
          {row?.phone_no && (
            <Typography fontSize="15px">{row.phone_no}</Typography>
          )}
        </>
      ) : (
        <Typography fontSize="15px">--</Typography>
      )}
    </>
  );
};

export default EmailPhoneCell;
