import React from "react";
import { Box, Container } from "@mui/system";

const ThankYouPage = () => {
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 20,
          mb: 4,
        }}
      >
        <img
          alt="Thank You..."
          src="/assets/thank-you-icon.png"
          width={200}
          height={200}
        />
      </Box>
    </Container>
  );
};

export default ThankYouPage;
