import React from "react";
import { Chip, IconButton } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PeopleIcon from "@mui/icons-material/People";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";

const getStatusChip = (status) => {
  const statusProps = {
    complete: {
      color: "success",
      icon: <CheckCircleIcon fontSize="small" />,
    },
    enabled: {
      color: "success",
      icon: <CheckCircleIcon fontSize="small" color="success"/>,
    },
    partial: { color: "warning", icon: <TrendingUpIcon fontSize="small" /> },
    pending: { color: "default", icon: <PeopleIcon fontSize="small" /> },
    disabled: {
      color: "default",
      icon: <NotInterestedIcon fontSize="small" />,
    },
  };

  if (status === "enabled" || status === "disabled") {
    return (
      // <Chip
      //   icon={statusProps[status]?.icon}
      //   color={statusProps[status]?.color}
      //   size="small"
      //   sx={{
      //     padding: 0.4,
      //     ".MuiChip-label": {
      //       display: "none",
      //     },
      //     ".MuiChip-icon": {
      //       margin: 0,
      //     },
      //   }}
      // />
      <IconButton>{statusProps[status]?.icon}</IconButton>
    );
  } else {
    return (
      <Chip
        icon={statusProps[status]?.icon}
        label={status}
        sx={{ textTransform: 'capitalize' }}
        color={statusProps[status]?.color}
        size="small"
      />
    );
  }
};

const StatusCell = ({ row }) => {
  return <div>{row.text ? getStatusChip(row?.text) : "--"}</div>;
};

export default StatusCell;
