import React from "react";
import { 
  Checkbox, 
  FormControlLabel, 
  FormGroup, 
  Radio, 
  RadioGroup, 
  Rating, 
  TextField 
} from "@mui/material";

const RenderAnswerType = ({ 
  question, 
  selectedAnswers, 
  setSelectedAnswers 
}) => {
  const handleTextChange = (questionId, value) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: value
    }));
  };

  const handleCheckboxChange = (questionId, option) => {
    setSelectedAnswers((prev) => {
      const current = prev[questionId] || [];
      const updated = current.includes(option)
        ? current.filter((item) => item !== option)
        : [...current, option];
      return { ...prev, [questionId]: updated };
    });
  };

  const handleRadioChange = (questionId, value) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: value
    }));
  };

  const handleRatingChange = (questionId, value) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: value
    }));
  };
  
  switch (question.answerType) {
    case "text":
      return (
        <TextField 
          fullWidth 
          placeholder="Text answer" 
          value={selectedAnswers?.[question.id] || ''}
          onChange={(e) => handleTextChange(question.id, e.target.value)}
          required
        />
      );
    case "number":
      return (
        <TextField 
          type="number" 
          fullWidth 
          placeholder="Numeric answer" 
          value={selectedAnswers?.[question.id] || ''}
          onChange={(e) => handleTextChange(question.id, e.target.value)}
          required
        />
      );
    case "multiple":
      return (
        <FormGroup>
          {question.answers.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={(selectedAnswers?.[question.id] || []).includes(option)}
                  onChange={() => handleCheckboxChange(question.id, option)}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
      );
    case "single":
      return (
        <RadioGroup
          value={selectedAnswers?.[question.id] || ''}
          onChange={(e) => handleRadioChange(question.id, e.target.value)}
        >
          {question.answers.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      );
    case "rating":
      return (
        <Rating 
          value={selectedAnswers?.[question.id] || 0}
          onChange={(e, newValue) => handleRatingChange(question.id, newValue)}
        />
      );
    case "yesno":
      return (
        <RadioGroup 
          row 
          value={selectedAnswers?.[question.id] || ''}
          onChange={(e) => handleRadioChange(question.id, e.target.value)}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      );
    default:
      return null;
  }
};

export default RenderAnswerType;