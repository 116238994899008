import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  Rating,
  Stack,
  Menu,
  Chip,
  Checkbox,
  FormGroup,
  Divider,
  InputAdornment,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Add as AddIcon,
  ZoomIn as ZoomInIcon,
  Leaderboard as LeaderboardIcon,
  Email as EmailIcon,
  Link as LinkIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon,
  ContentCopy as ContentCopyIcon,
  Search as SearchIcon,
  CalendarMonth as CalendarMonthIcon,
  Quiz as QuizIcon,
  Groups2 as Groups2Icon,
} from "@mui/icons-material";
import Toast from "../Tost";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ConfirmationBox from "../ConfirmationBox";
import { format } from "date-fns";
import EmptyRecords from "../EmptyRecord";

const questionTypes = [
  { value: "text", label: "Text Answer" },
  { value: "number", label: "Numeric Answer" },
  { value: "multiple", label: "Multiple Choice (Multiple Answers)" },
  { value: "single", label: "Multiple Choice (Single Answer)" },
  { value: "rating", label: "Rating Scale" },
  { value: "yesno", label: "Yes/No" },
];

const getDefaultAnswers = (type) => {
  switch (type) {
    case "rating":
      return ["1", "2", "3", "4", "5"];
    case "yesno":
      return ["Yes", "No"];
    case "multiple":
      return ["Option 1"];
    default:
      return [];
  }
};

const initialSurveys = [
  {
    surveyId: 1,
    title: "Customer Feedback Survey",
    status: "published",
    is_anonymous: false,
    respondents_count: 3,
    description:
      "A survey to collect customer feedback on our products. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe sint pariatur ipsum, quam, blanditiis quod voluptates sapiente laudantium iure reiciendis placeat incidunt hic nisi fuga vitae tempore repudiandae doloremque repellendus.",
    published_date: "2024-11-25",
    updated_date: "2024-11-30",
    started_on: "",
    ends_on: "",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with our product?",
        answerType: "rating",
        answers: "3",
      },
      {
        id: 2,
        question: "Would you recommend our product to others?",
        answerType: "yesno",
        answers: "Yes",
      },
    ],
  },
  {
    surveyId: 3,
    title: "Employee Satisfaction Survey",
    status: "paused",
    is_anonymous: false,
    respondents_count: 0,
    description: "A survey to gauge employee satisfaction and engagement.",
    published_date: "2024-11-20",
    updated_date: "",
    started_on: "2024-11-21",
    ends_on: "2024-12-04",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with your work environment?",
        answerType: "rating",
        answers: "3",
      },
      {
        id: 2,
        question: "Would you recommend this company as a great place to work?",
        answerType: "yesno",
        answers: "No",
      },
      {
        id: 3,
        question: "Which benefits do you find most valuable?",
        answerType: "multiple",
        answers: ["Health Insurance", "Paid Time Off", "Insurance", "Paid Off"],
      },
      {
        id: 4,
        question: "How would you rate communication within your team?",
        answerType: "rating",
        answers: "4",
      },
      {
        id: 5,
        question:
          "What improvements would you suggest for the work environment?",
        answerType: "text",
        answers: "More team-building activities",
      },
    ],
  },
  {
    surveyId: 4,
    title: "Product Feedback Survey",
    status: "draft",
    is_anonymous: true,
    respondents_count: 3,
    description: "A survey to gather feedback on a new product release.",
    published_date: "2024-10-28",
    updated_date: "2024-11-02",
    started_on: "",
    ends_on: "",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with the new product?",
        answerType: "rating",
        answers: "4",
      },
      {
        id: 2,
        question: "Would you buy this product again?",
        answerType: "yesno",
        answers: "Yes",
      },
      {
        id: 3,
        question: "What features do you like the most?",
        answerType: "multiple",
        answers: ["Design", "Handling", "Layout", "Ease of Use"],
      },
      {
        id: 4,
        question: "How would you rate the value for money of this product?",
        answerType: "rating",
        answers: "5",
      },
      {
        id: 5,
        question: "What would you improve in this product?",
        answerType: "text",
        answers: "Add more color options",
      },
    ],
  },
  {
    surveyId: 5,
    title: "Customer Support Experience Survey",
    status: "closed",
    is_anonymous: false,
    respondents_count: 10,
    description: "A survey to evaluate customer support interactions.",
    published_date: "2024-10-10",
    updated_date: "",
    started_on: "",
    ends_on: "",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with the support you received?",
        answerType: "rating",
        answers: "5",
      },
      {
        id: 2,
        question: "Did the support team resolve your issue effectively?",
        answerType: "yesno",
        answers: "Yes",
      },
      {
        id: 3,
        question: "How easy was it to reach customer support?",
        answerType: "multiple",
        answers: ["Phone", "Email", "Chat", "Website"],
      },
      {
        id: 4,
        question: "How would you rate the professionalism of the support team?",
        answerType: "rating",
        answers: "5",
      },
      {
        id: 5,
        question: "What suggestions do you have for improving support?",
        answerType: "text",
        answers: "Faster response time",
      },
    ],
  },
  {
    surveyId: 6,
    title: "New Survey",
    status: "draft",
    is_anonymous: false,
    respondents_count: 0,
    description: "Testing Survey",
    published_date: "2024-12-02",
    updated_date: "",
    started_on: "",
    ends_on: "",
    questions: [],
  },
];

const formatDate = (date) => {
  return format(new Date(date), "dd-MMM-yyyy");
};

const MySurveys = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [surveys, setSurveys] = useState(initialSurveys);
  const [filePath, setFilePath] = useState("");
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState(searchParams.get("status") || "");
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [surveyLink, setSurveyLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [radioValue, setRadioValue] = useState("named_survey");
  const respondentDetails = [
    "Respondent Name",
    "Email",
    "Phone Number",
    "Gender",
  ];
  const [selectedRespondentDetailsAns, setSelectedRespondentDetailsAns] =
    useState([]);
  const [newSurvey, setNewSurvey] = useState({
    title: "",
    description: "",
    status: "Draft",
    questions: [],
  });

  const options = [
    {
      label: "Preview survey form",
      action: "Preview Survey Form",
      icon: <ZoomInIcon fontSize="small" />,
    },
    {
      label: "Analyze responses",
      action: "Analyze Responses",
      icon: <LeaderboardIcon fontSize="small" />,
    },
    {
      label: "Share weblink",
      action: "Get Web Link",
      icon: <LinkIcon fontSize="small" />,
    },
    {
      label: "Share via email",
      action: "Share Via Email",
      icon: <EmailIcon fontSize="small" />,
    },
    {
      label: "Delete",
      action: "Delete",
      icon: <DeleteIcon fontSize="small" />,
    },
  ];

  const generateSurveyLink = (surveyId) => {
    const baseUrl =
      selectedSurvey.status === "Anonymous"
        ? "http://localhost:3000/feedback-form"
        : "http://localhost:3000/feedback-login-form";
    const uniqueId = btoa(`survey_${surveyId}_${Date.now()}`);
    return `${baseUrl}/survey/${uniqueId}`;
  };

  const copyLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(surveyLink);
      setToastMessage("Link copied to clipboard!");
      setToastType("success");
      setToast(true);
    } catch (err) {
      setToastMessage("Failed to copy link");
      setToastType("error");
      setToast(true);
    }
  };

  const handleRespDetailCheckboxChange = (option) => {
    setSelectedRespondentDetailsAns((prev) =>
      prev.includes(option)
        ? prev.filter((answer) => answer !== option)
        : [...prev, option]
    );
  };

  const handleCheckboxChange = (questionId, option) => {
    setSelectedAnswers((prev) => {
      const current = prev[questionId] || [];
      const updated = current.includes(option)
        ? current.filter((item) => item !== option)
        : [...current, option];
      return { ...prev, [questionId]: updated };
    });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const filteredSurveys = surveys.filter((survey) => {
    const matchesSearch = survey.title.toLowerCase().includes(searchQuery);
    const matchesStatus = statusFilter === "" || survey.status === statusFilter;
    return matchesSearch && matchesStatus;
  });

  const handleMenuOpen = (event, survey) => {
    setMenuAnchor(event.currentTarget);
    setSelectedSurvey(survey);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedSurvey(null);
  };

  const handleDeleteConfirm = (survey) => {
    setSurveyToDelete(survey);
    setDeleteConfirmOpen(true);
  };

  const handleMenuOptionClick = (option) => {
    if (option === "Share Via Email") {
      navigate("/email-invitation");
    } else if (option === "Get Web Link") {
      const link = generateSurveyLink(selectedSurvey.surveyId);
      setSurveyLink(link);
      setLinkDialogOpen(true);
    } else if (option === "Analyze Responses") {
      navigate("/anaylze-responses");
    } else if (option === "Preview Survey Form") {
      const baseUrl = "http://localhost:3000/survey-preview";
      const uniqueId = btoa(`survey_${selectedSurvey.surveyId}_${Date.now()}`);
      const newWindowUrl = `${baseUrl}/${uniqueId}`;

      window.open(newWindowUrl, "_blank");
    } else if (option === "Delete") {
      handleDeleteConfirm(selectedSurvey);
    }
    handleMenuClose();
  };

  const handleChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleLinkDialogClose = () => {
    setLinkDialogOpen(false);
    setSurveyLink("");
  };

  const handleCreate = () => {
    setIsCreating(true);
    setIsAnonymous(false);
    setNewSurvey({
      title: "",
      description: "",
      status: "draft",
      questions: [],
    });
  };

  const handleDelete = () => {
    setSurveys(
      surveys.filter((survey) => survey.surveyId !== surveyToDelete.surveyId)
    );
    setDeleteConfirmOpen(false);
    setSurveyToDelete(null);
  };

  const handleClose = () => {
    setIsEditing(false);
    setIsCreating(false);
    setIsViewing(false);
  };

  const handleSave = () => {
    if (!newSurvey.title.trim()) {
      alert("Please enter a survey title");
      return;
    }

    // if (newSurvey.questions.length === 0) {
    //   alert("Please add at least one question");
    //   return;
    // }

    const surveyWithAnswers = {
      ...newSurvey,
      questions: newSurvey.questions.map((q) => ({
        ...q,
        selectedAnswers: q.answerType === "multiple" ? [] : undefined,
      })),
    };

    if (isEditing) {
      setSurveys(
        surveys.map((survey) =>
          survey.surveyId === selectedSurvey.surveyId
            ? surveyWithAnswers
            : survey
        )
      );
      setIsEditing(false);
    } else if (isCreating) {
      setSurveys([
        ...surveys,
        {
          ...surveyWithAnswers,
          surveyId: surveys.length + 1,
          questions: surveyWithAnswers.questions.map((q, idx) => ({
            ...q,
            id: idx + 1,
          })),
        },
      ]);
      setIsCreating(false);
    }
    setNewSurvey({
      title: "",
      description: "",
      status: "draft",
      questions: [
        {
          id: 1,
          question: "",
          answerType: "text",
          answers: [],
          isEditing: true,
          originalQuestion: null,
        },
      ],
    });
    setSelectedAnswers({});
    setToast(true);
    setToastMessage("Survey created successfully.");
    setToastType("success");
    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  const renderAnswerType = (question) => {
    switch (question.answerType) {
      case "text":
        return <TextField fullWidth placeholder="Text answer" />;
      case "number":
        return (
          <TextField type="number" fullWidth placeholder="Numeric answer" />
        );
      case "multiple":
        return (
          <FormGroup>
            {question.answers.map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={(selectedAnswers[question.id] || []).includes(
                      option
                    )}
                    onChange={() => handleCheckboxChange(question.id, option)}
                  />
                }
                label={option}
              />
            ))}
          </FormGroup>
        );
      case "single":
        return (
          <RadioGroup>
            {question.answers.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        );
      case "rating":
        return <Rating />;
      case "yesno":
        return (
          <RadioGroup row>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        );
      default:
        return null;
    }
  };

  const renderSurveyForm = () => (
    <Dialog
      open={isEditing || isCreating}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>
        {isEditing ? "Edit Survey" : "Create New Survey"}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ py: 3 }}>
        <Stack spacing={3}>
          {/* Survey Type Section */}
          <FormControl>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Survey Type
            </Typography>
            <RadioGroup
              aria-labelledby="survey-type-radio-buttons-group"
              name="survey-type"
              value={radioValue}
              onChange={handleChange}
            >
              <FormControlLabel
                value="named_survey"
                control={<Radio />}
                label={
                  <Typography variant="body1">
                    <strong>Named Survey</strong> (Respondent needs to share
                    personal details before starting.)
                  </Typography>
                }
              />
              <FormControlLabel
                value="anonymous"
                control={<Radio />}
                label={
                  <Typography variant="body1">
                    <strong>Anonymous</strong> (Respondent details will not be
                    collected at all.)
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>

          {/* Respondent Details Section */}
          {radioValue === "anonymous" && (
            <FormGroup>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Respondent Details to Be Collected
              </Typography>
              {respondentDetails.map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selectedRespondentDetailsAns.includes(option)}
                      onChange={() => handleRespDetailCheckboxChange(option)}
                    />
                  }
                  label={option}
                />
              ))}
            </FormGroup>
          )}

          {/* Add Logo Section */}
          <Box>
            <Typography variant="h6" fontWeight="bold">
              Add Logo
            </Typography>
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              (This logo will appear on the top of the survey page and can be
              used as a company branding.)
            </Typography>
            <TextField
              id="file_path"
              accept="image/doc,pdf,jpeg,jpg,png"
              label="Upload File"
              name="file_path"
              type="file"
              onChange={(e) => setFilePath(e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={{ mt: 1 }}
            />
          </Box>

          {/* Survey Details Section */}
          <Box>
            <Typography variant="h6" fontWeight="bold">
              Survey Details
            </Typography>
            <TextField
              label="Survey Title"
              fullWidth
              value={newSurvey.title}
              onChange={(e) =>
                setNewSurvey({ ...newSurvey, title: e.target.value })
              }
              required
              sx={{ mt: 1 }}
            />
            <TextField
              label="Survey Description"
              fullWidth
              multiline
              rows={3}
              value={newSurvey.description}
              onChange={(e) =>
                setNewSurvey({ ...newSurvey, description: e.target.value })
              }
              sx={{ mt: 2 }}
            />
          </Box>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button
          size="small"
          onClick={handleClose}
          color="error"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          size="small"
          onClick={handleSave}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderSurveyView = () => (
    <Dialog
      open={isViewing}
      onClose={() => setIsViewing(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>
        {selectedSurvey ? selectedSurvey.title : "Loading..."}
        <Typography variant="body1" color="text.secondary">
          {selectedSurvey ? selectedSurvey.description : "Loading..."}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {selectedSurvey ? (
          <Stack spacing={3}>
            {selectedSurvey.questions?.length > 0 ? (
              selectedSurvey.questions?.map((question) => (
                <Paper key={question.id} sx={{ p: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {question.question}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    display="block"
                    gutterBottom
                  >
                    {
                      questionTypes.find((t) => t.value === question.answerType)
                        ?.label
                    }
                  </Typography>
                  {renderAnswerType(question)}
                </Paper>
              ))
            ) : (
              <Typography variant="body1" color="text.secondary">
                No questions in this survey.
              </Typography>
            )}
          </Stack>
        ) : (
          <Typography variant="body1" color="text.secondary">
            Loading survey details...
          </Typography>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2 }}>
        <Button
          onClick={() => {
            setIsViewing(false);
            setSelectedAnswers({});
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderLinkDialog = () => (
    <Dialog open={linkDialogOpen} onClose={handleLinkDialogClose} maxWidth="md">
      <DialogTitle sx={{ fontWeight: "bold" }}>Survey Link</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Share this link to allow people to fill out your survey:
          </Typography>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              gap: 2,
              bgcolor: "grey.50",
            }}
          >
            <TextField
              fullWidth
              value={surveyLink}
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              variant="contained"
              startIcon={<ContentCopyIcon />}
              onClick={copyLinkToClipboard}
            >
              Copy
            </Button>
          </Paper>
        </Box>
        <Typography variant="body2" color="text.secondary">
          This link will be available until you delete or unpublish the survey.
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2 }}>
        <Button onClick={handleLinkDialogClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h4" component="h1">
          Surveys
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Create Survey
        </Button>
      </Box>

      {/* Filters */}
      <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by Surveys title"
          value={searchQuery}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearchChange}
        />
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={handleStatusFilterChange}
            label="Status"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="published">Published</MenuItem>
            <MenuItem value="draft">Draft</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Stack spacing={2}>
        {filteredSurveys.map((survey) => (
          <Card key={survey.surveyId}>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {survey?.title}
                    {survey?.is_anonymous && (
                      <span style={{ marginLeft: "8px" }}>
                        <Chip
                          label={"Anonymous"}
                          size="small"
                          style={{
                            backgroundColor: "purple",
                            color: "#fff",
                            fontSize: "12px",
                          }}
                        />
                      </span>
                    )}
                    <span style={{ marginLeft: "8px" }}>
                      <Chip
                        label={survey.status}
                        sx={{ textTransform: "capitalize" }}
                        size="small"
                        style={
                          survey.status === "published"
                            ? {
                                backgroundColor: "green",
                                color: "#fff",
                                fontSize: "12px",
                              }
                            : survey.status === "draft"
                            ? {
                                backgroundColor: "Gray",
                                color: "#fff",
                                fontSize: "12px",
                              }
                            : survey.status === "paused"
                            ? {
                                backgroundColor: "#FFC107",
                                color: "#fff",
                                fontSize: "12px",
                              }
                            : {
                                backgroundColor: "Red",
                                color: "#fff",
                                fontSize: "12px",
                              }
                        }
                      />
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{ textAlign: "justify" }}
                  >
                    {survey?.description}
                  </Typography>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={0.6}
                    my={0.5}
                  >
                    <CalendarMonthIcon
                      style={{ width: "16px", height: "16px" }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {survey?.updated_date ? (
                        <>
                          <strong>Last Updated Date:</strong>{" "}
                          {survey?.updated_date
                            ? formatDate(survey?.updated_date)
                            : "--"}
                        </>
                      ) : (
                        <>
                          <strong>Published Date:</strong>{" "}
                          {survey?.published_date
                            ? formatDate(survey?.published_date)
                            : "--"}
                        </>
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={2}
                    my={0.5}
                  >
                    <Typography variant="body2" color="text.secondary">
                      <strong>Started On: </strong>
                      {survey?.started_on
                        ? formatDate(survey?.started_on)
                        : formatDate(survey?.published_date)}
                    </Typography>
                    {survey?.ends_on && (
                      <Typography variant="body2" color="text.secondary">
                        <strong>Ends On: </strong>
                        {formatDate(survey?.ends_on)}
                      </Typography>
                    )}
                  </Stack>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "end",
                  }}
                >
                  <Box>
                    <IconButton
                      onClick={(event) => handleMenuOpen(event, survey)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="end"
                gap={3}
                sx={{ px: 2, mt: 2 }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <QuizIcon sx={{ color: "Gray" }} />
                  <Link
                    to={`/survey-details/${btoa(
                      `${survey.surveyId}_${Date.now()}`
                    )}`}
                    style={{
                      textDecoration: "none",
                      color: "blue",
                      cursor: "pointer",
                      borderBottom: "2px solid blue",
                    }}
                  >
                    {survey.questions ? survey.questions?.length : 0} Questions
                  </Link>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Groups2Icon sx={{ color: "Gray" }} />
                  <Link
                    to={`/survey-responses/${btoa(
                      `${survey.surveyId}_${Date.now()}`
                    )}`}
                    style={{
                      textDecoration: "none",
                      color: "blue",
                      cursor: "pointer",
                      borderBottom: "2px solid blue",
                    }}
                  >
                    {survey?.respondents_count ? survey.respondents_count : 0}{" "}
                    Responses
                  </Link>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        ))}
        {filteredSurveys.length === 0 && (
          <Card>
            <CardContent>
              <EmptyRecords listLoading={isLoading} emptyRecordText={"No surveys has been found."}/>
            </CardContent>
          </Card>
        )}
      </Stack>

      {/* Popup menu */}
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.action}
            onClick={() => handleMenuOptionClick(option.action)}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>

      {renderSurveyForm()}
      {renderSurveyView()}
      {renderLinkDialog()}

      <ConfirmationBox
        open={deleteConfirmOpen}
        headerText="Confirm Delete"
        close={() => setDeleteConfirmOpen(false)}
        handleClose={() => setDeleteConfirmOpen(false)}
        handleAction={handleDelete}
      >
        <Typography variant="body2">
          {`Are you sure want to delete `}
          <strong>{`"${surveyToDelete?.title}"`}</strong>
          {` ?`}
        </Typography>
      </ConfirmationBox>
      {toast && (
        <Toast
          setToast={setToast}
          isLoading={isLoading}
          toast={toast}
          toastType={toastType}
          toastMessage={toastMessage}
        />
      )}
    </Container>
  );
};

export default MySurveys;
