import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./components/auth/PrivateRoute";

// Pages and Components
import HomePage from "./pages/HomePage";
import LoginPage from "./components/auth/LoginPage";
import SurveyPage from "./pages/SurveyPage";
import AdminPage from "./pages/AdminPage";
import SurveyDashboard from "./components/dashboard/SurveyDashboard";
import SignupPage from "./components/auth/SignUpPage";
import ResponseCollection from "./components/survey/ResponseCollection";
import { Navigation } from "./components/navigation/naviagtion";
import ResultAnalytics from "./pages/ResultAnalytics";
import SurveyForm from "./pages/SurveyForm";
import ThankYouPage from "./pages/ThankYouPage";
import SurveyDetails from "./components/survey/SurveyDetails";
import SurveyFormFillHandle from "./pages/SurveyFormFillHandle";
import EmailInvitation from "./components/survey/EmailInvitation";
import SurveyResponseAnalysis from "./pages/SurveyResponseAnalysis";
import SurveyPreview from "./pages/SurveyPreview";
import EmailVerification from "./components/auth/EmailVerification";
import CompanyOnboarding from "./components/auth/CompanyOnboarding";

// Create theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#0C0C5E",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#E4E6F5",
    },
  },
  typography: {
    h4: {
      fontWeight: 500,
      color: "#232946",
    },
    h5: {
      fontWeight: 500,
      color: "#232946",
    },
    h6: {
      fontWeight: 500,
      color: "#232946",
    },
    subtitle1: {
      color: "#232946",
    },
  },
  Card: {
    backgroundColor: "pink",
  },
  // components: {
  //   MuiCssBaseline: {
  //     styleOverrides: {
  //       body: {
  //         backgroundImage: "linear-gradient(180deg, #c7dbff 0%, #839efe 50%, #6a8eff 100%)",
  //       },
  //     },
  //   },
  // },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <Navigation />
            <Box component="main" sx={{ flexGrow: 1 }}>
              <Routes>
                {/* Public routes */}
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route
                  path="/email-verification"
                  element={<EmailVerification />}
                />
                <Route
                  path="/company-onboarding"
                  element={<CompanyOnboarding />}
                />

                {/* Public route that allows guest access */}
                <Route
                  path="/feedback-form/survey/:surveyId"
                  element={
                    <PrivateRoute allowGuest={true}>
                      <SurveyForm />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/feedback-login-form/survey/:surveyId"
                  element={
                    <PrivateRoute allowGuest={true}>
                      <SurveyFormFillHandle />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/thank-you"
                  element={
                    <PrivateRoute allowGuest={true}>
                      <ThankYouPage />
                    </PrivateRoute>
                  }
                />

                {/* Protected routes */}
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <SurveyDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/my-surveys"
                  element={
                    <PrivateRoute>
                      <SurveyPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/survey-details/:surveyId"
                  element={
                    <PrivateRoute>
                      <SurveyDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/survey-preview/:surveyId"
                  element={
                    <PrivateRoute allowGuest={true}>
                      <SurveyPreview />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/email-invitation"
                  element={
                    <PrivateRoute>
                      <EmailInvitation />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/anaylze-responses"
                  element={
                    <PrivateRoute>
                      <SurveyResponseAnalysis />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/survey-responses/:surveyId"
                  element={
                    <PrivateRoute>
                      <ResponseCollection />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/result-analytics"
                  element={
                    <PrivateRoute>
                      <ResultAnalytics />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <PrivateRoute requiredRole="admin">
                      <AdminPage />
                    </PrivateRoute>
                  }
                />

                {/* Catch all route */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Box>
          </Box>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
