import React, { useState } from "react";
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import ConfirmationBox from "../../ConfirmationBox";
import { Stack } from "@mui/system";
import RenderAnswerType from "../../RenderAnswerType";

const ActionCell = ({ row, type, handleDeleteQuestion, handleEditQuestion }) => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState({});

  const questionTypes = [
    { value: "text", label: "Text Answer" },
    { value: "number", label: "Numeric Answer" },
    { value: "multiple", label: "Multiple Choice (Multiple Answers)" },
    { value: "single", label: "Multiple Choice (Single Answer)" },
    { value: "rating", label: "Rating Scale" },
    { value: "yesno", label: "Yes/No" },
  ];

  const questionView = () => (
    <Dialog
      open={isViewing}
      onClose={() => setIsViewing(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ fontWeight: "bold" }}>
        Question View
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack>
          <Paper sx={{ p: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              {row.question}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              display="block"
              gutterBottom
            >
              {questionTypes.find((t) => t.value === row?.answerType)?.label}
            </Typography>
            <RenderAnswerType
              question={row}
              selectedAnswers={selectedAnswers}
              setSelectedAnswers={setSelectedAnswers}
            />
          </Paper>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2 }}>
        <Button
          onClick={() => {
            setIsViewing(false);
            setSelectedAnswers({});
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      <IconButton onClick={() => setIsViewing(true)} color="primary">
        <VisibilityIcon />
      </IconButton>
      <IconButton onClick={() => handleEditQuestion(row)} color="info">
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => setDeleteConfirmOpen(true)} color="error">
        <DeleteIcon />
      </IconButton>
      {questionView()}
      <ConfirmationBox
        open={deleteConfirmOpen}
        headerText="Confirm Delete"
        close={() => setDeleteConfirmOpen(false)}
        handleClose={() => setDeleteConfirmOpen(false)}
        handleAction={() => {
          handleDeleteQuestion(row?.id);
          setDeleteConfirmOpen(false);
        }}
      >
        <Typography variant="body2">
          Are you sure you want to delete this question?
        </Typography>
      </ConfirmationBox>
    </div>
  );
};

export default ActionCell;
