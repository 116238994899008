import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import RenderSurvey from "../components/RenderSurvey";
import { useParams } from "react-router-dom";
import { Paper } from "@mui/material";

const initialSurveys = [
  {
    surveyId: 1,
    title: "Customer Feedback Survey",
    status: "Published",
    description: "A survey to collect customer feedback on our products.",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with our product?",
        answerType: "rating",
        answers: "3",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-25",
      },
      {
        id: 2,
        question: "Would you recommend our product to others?",
        answerType: "yesno",
        answers: "Yes",
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-26",
      },
    ],
  },
  {
    surveyId: 3,
    title: "Employee Satisfaction Survey",
    status: "Draft",
    description: "A survey to gauge employee satisfaction and engagement.",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with your work environment?",
        answerType: "rating",
        answers: "3",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-20",
      },
      {
        id: 2,
        question: "Would you recommend this company as a great place to work?",
        answerType: "yesno",
        answers: "No",
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-21",
      },
      {
        id: 3,
        question: "Which benefits do you find most valuable?",
        answerType: "multiple",
        answers: [
          "Health Insurance",
          "Paid Time Off",
          "Health Insurance",
          "Paid Time Off",
        ],
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-24",
      },
      {
        id: 4,
        question: "How would you rate communication within your team?",
        answerType: "rating",
        answers: "4",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-24",
      },
      {
        id: 5,
        question:
          "What improvements would you suggest for the work environment?",
        answerType: "text",
        answers: "More team-building activities",
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-26",
      },
      {
        id: 6,
        question:
          "What improvements would you suggest for the work environment?",
        answerType: "text",
        answers: "More team-building activities",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
      {
        id: 7,
        question:
          "What improvements would you suggest for the work environment?",
        answerType: "text",
        answers: "More team-building activities",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
    ],
  },
  {
    surveyId: 4,
    title: "Product Feedback Survey",
    status: "Anonymous",
    description: "A survey to gather feedback on a new product release.",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with the new product?",
        answerType: "rating",
        answers: "4",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-25",
      },
      {
        id: 2,
        question: "Would you buy this product again?",
        answerType: "yesno",
        answers: "Yes",
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-25",
      },
      {
        id: 3,
        question: "What features do you like the most?",
        answerType: "multiple",
        answers: ["Design", "Ease of Use", "Design", "Ease of Use"],
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
      {
        id: 4,
        question: "How would you rate the value for money of this product?",
        answerType: "rating",
        answers: "5",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
      {
        id: 5,
        question: "What would you improve in this product?",
        answerType: "text",
        answers: "Add more color options",
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-27",
      },
    ],
  },
  {
    surveyId: 5,
    title: "Customer Support Experience Survey",
    status: "Draft",
    description: "A survey to evaluate customer support interactions.",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with the support you received?",
        answerType: "rating",
        answers: "5",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
      {
        id: 2,
        question: "Did the support team resolve your issue effectively?",
        answerType: "yesno",
        answers: "Yes",
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-26",
      },
      {
        id: 3,
        question: "How easy was it to reach customer support?",
        answerType: "multiple",
        answers: ["Phone", "Email", "Chat", "Website"],
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
      {
        id: 4,
        question: "How would you rate the professionalism of the support team?",
        answerType: "rating",
        answers: "5",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
      {
        id: 5,
        question: "What suggestions do you have for improving support?",
        answerType: "text",
        answers: "Faster response time",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
    ],
  },
  {
    surveyId: 6,
    title: "New Survey",
    status: "Draft",
    description: "Testing Survey",
    questions: [],
  },
];

const SurveyPreview = () => {
  const { surveyId } = useParams();
  const [survey, setSurvey] = useState();

  useEffect(() => {
    console.log("surveyId", surveyId);
    const decodedString = atob(surveyId).split("_")[1];
    const filterSurvey = initialSurveys.filter(
      (survey) => survey.surveyId === Number(decodedString)
    )[0];
    setSurvey(filterSurvey);
  }, [surveyId]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <RenderSurvey data={survey} />
      </Paper>
    </Container>
  );
};

export default SurveyPreview;
