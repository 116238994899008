import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Paper,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Rating,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

const SurveyList = () => {
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);

  // Simulated data loading - replace with actual API call
  useEffect(() => {
    const surveyData = [
      {
        surveyId: 101,
        title: "Customer Satisfaction Survey",
        description: "A survey to gather customer feedback about our services.",
        questions: [
          {
            id: 1,
            question: "How satisfied are you with our service?",
            answerType: "Rating",
            answers: "4",
          },
          {
            id: 2,
            question: "Would you recommend our service to others?",
            answerType: "Yes/No",
            answers: "Yes",
          },
          {
            id: 3,
            question: "What features do you use the most?",
            answerType: "Multiple Choice",
            answers: ["Online Support", "Mobile App"],
          },
          {
            id: 4,
            question: "How would you rate the quality of our customer support?",
            answerType: "Rating",
            answers: "5",
          },
          {
            id: 5,
            question: "What improvements would you suggest?",
            answerType: "Text Answer",
            answers: "Improve the mobile app's UI",
          },
          {
            id: 6,
            question: "How likely are you to use our service again?",
            answerType: "Numeric Answer",
            answers: "9",
          },
        ],
      },
      {
        surveyId: 102,
        title: "Employee Satisfaction Survey",
        description: "A survey to gauge employee satisfaction and engagement.",
        questions: [
          {
            id: 1,
            question: "How satisfied are you with your work environment?",
            answerType: "Rating",
            answers: "3",
          },
          {
            id: 2,
            question:
              "Would you recommend this company as a great place to work?",
            answerType: "Yes/No",
            answers: "No",
          },
          {
            id: 3,
            question: "Which benefits do you find most valuable?",
            answerType: "Multiple Choice",
            answers: ["Health Insurance", "Paid Time Off"],
          },
          {
            id: 4,
            question: "How would you rate communication within your team?",
            answerType: "Rating",
            answers: "4",
          },
          {
            id: 5,
            question:
              "What improvements would you suggest for the work environment?",
            answerType: "Text Answer",
            answers: "More team-building activities",
          },
        ],
      },
      {
        surveyId: 103,
        title: "Product Feedback Survey",
        description: "A survey to gather feedback on a new product release.",
        questions: [
          {
            id: 1,
            question: "How satisfied are you with the new product?",
            answerType: "Rating",
            answers: "4",
          },
          {
            id: 2,
            question: "Would you buy this product again?",
            answerType: "Yes/No",
            answers: "Yes",
          },
          {
            id: 3,
            question: "What features do you like the most?",
            answerType: "Multiple Choice",
            answers: ["Design", "Ease of Use"],
          },
          {
            id: 4,
            question: "How would you rate the value for money of this product?",
            answerType: "Rating",
            answers: "5",
          },
          {
            id: 5,
            question: "What would you improve in this product?",
            answerType: "Text Answer",
            answers: "Add more color options",
          },
        ],
      },
      {
        surveyId: 104,
        title: "Customer Support Experience Survey",
        description: "A survey to evaluate customer support interactions.",
        questions: [
          {
            id: 1,
            question: "How satisfied are you with the support you received?",
            answerType: "Rating",
            answers: "5",
          },
          {
            id: 2,
            question: "Did the support team resolve your issue effectively?",
            answerType: "Yes/No",
            answers: "Yes",
          },
          {
            id: 3,
            question: "How easy was it to reach customer support?",
            answerType: "Multiple Choice",
            answers: ["Phone", "Email", "Chat"],
          },
          {
            id: 4,
            question:
              "How would you rate the professionalism of the support team?",
            answerType: "Rating",
            answers: "5",
          },
          {
            id: 5,
            question: "What suggestions do you have for improving support?",
            answerType: "Text Answer",
            answers: "Faster response time",
          },
        ],
      },
      {
        surveyId: 105,
        title: "Event Feedback Survey",
        description: "A survey to gather feedback on a recent event.",
        questions: [
          {
            id: 1,
            question: "How satisfied are you with the event?",
            answerType: "Rating",
            answers: "4",
          },
          {
            id: 2,
            question: "Would you attend another event like this?",
            answerType: "Yes/No",
            answers: "Yes",
          },
          {
            id: 3,
            question: "What aspects of the event did you enjoy the most?",
            answerType: "Multiple Choice",
            answers: ["Speakers", "Workshops", "Networking"],
          },
          {
            id: 4,
            question: "How would you rate the event organization?",
            answerType: "Rating",
            answers: "5",
          },
          {
            id: 5,
            question: "What improvements would you suggest for future events?",
            answerType: "Text Answer",
            answers: "Better food options",
          },
        ],
      },
    ];
    setSurveys(surveyData);
  }, []);

  const handleViewDetails = (survey) => {
    setSelectedSurvey(survey);
    setDetailsOpen(true);
  };

  const renderAnswerField = (answerType, answers) => {
    switch (answerType) {
      case "Rating":
        return (
          <Rating
            value={parseInt(answers, 10)}
            readOnly
            precision={1}
            sx={{ mt: 1 }}
          />
        );
      case "Yes/No":
        return (
          <RadioGroup row value={answers}>
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        );
      case "Multiple Choice":
        return (
          <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
            {answers.map((option, index) => (
              <FormControlLabel
                key={index}
                control={<Checkbox checked />}
                label={option}
              />
            ))}
          </Box>
        );
      case "Text Answer":
        return (
          <Typography variant="body1" sx={{ mt: 1 }}>
            {answers}
          </Typography>
        );
      case "Numeric Answer":
        return (
          <Typography variant="body1" sx={{ mt: 1 }}>
            {answers}
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
        Your Surveys
      </Typography>
      <Paper sx={{ p: 2 }}>
        <List>
          {surveys.map((survey) => (
            <React.Fragment key={survey.surveyId}>
              <ListItem
                display="flex"
                alignItems="center"
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      {survey.title}
                    </Box>
                  }
                  secondary={survey.description}
                />
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<VisibilityIcon />}
                  onClick={() => handleViewDetails(survey)}
                >
                  View
                </Button>
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          ))}
        </List>
      </Paper>

      {/* Survey Details Dialog */}
      <Dialog
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        maxWidth="md"
        fullWidth
      >
        {selectedSurvey && (
          <>
            <DialogTitle>
              <Typography variant="h5">{selectedSurvey.title}</Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {selectedSurvey.description}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1" gutterBottom>
                Questions and Answers
              </Typography>
              <List>
                {selectedSurvey.questions.map((question, index) => (
                  <ListItem key={question.id} alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {index + 1}. {question.question}
                        </Typography>
                      }
                      secondary={
                        <Box sx={{ paddingLeft: "18px" }}>
                          {renderAnswerField(
                            question.answerType,
                            question.answers
                          )}
                        </Box>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDetailsOpen(false)}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default SurveyList;
