import { useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { Box, Container } from "@mui/system";
import { Button, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import RenderSurvey from "../components/RenderSurvey";
import CircularProgress from "@mui/material/CircularProgress";

const initialSurveys = [
  {
    surveyId: 1,
    title: "Customer Feedback Survey",
    status: "Publish",
    description: "A survey to collect customer feedback on our products.",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with our product?",
        answerType: "rating",
        answers: "3",
        isEditing: true,
        originalQuestion: null,
      },
      {
        id: 2,
        question: "Would you recommend our product to others?",
        answerType: "yesno",
        answers: "Yes",
        isEditing: true,
        originalQuestion: null,
      },
    ],
  },
  {
    surveyId: 3,
    title: "Employee Satisfaction Survey",
    status: "Draft",
    description: "A survey to gauge employee satisfaction and engagement.",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with your work environment?",
        answerType: "rating",
        answers: "3",
        isEditing: true,
        originalQuestion: null,
      },
      {
        id: 2,
        question: "Would you recommend this company as a great place to work?",
        answerType: "yesno",
        answers: "No",
        isEditing: true,
        originalQuestion: null,
      },
      {
        id: 3,
        question: "Which benefits do you find most valuable?",
        answerType: "multiple",
        answers: ["Health Insurance", "Paid Time Off", "Insurance", "Paid Off"],
        isEditing: true,
        originalQuestion: null,
      },
      {
        id: 4,
        question: "How would you rate communication within your team?",
        answerType: "rating",
        answers: "4",
        isEditing: true,
        originalQuestion: null,
      },
      {
        id: 5,
        question:
          "What improvements would you suggest for the work environment?",
        answerType: "text",
        answers: "More team-building activities",
        isEditing: true,
        originalQuestion: null,
      },
    ],
  },
  {
    surveyId: 4,
    title: "Product Feedback Survey",
    status: "Anonymous",
    description: "A survey to gather feedback on a new product release.",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with the new product?",
        answerType: "rating",
        answers: "4",
        isEditing: true,
        originalQuestion: null,
      },
      {
        id: 2,
        question: "Would you buy this product again?",
        answerType: "yesno",
        answers: "Yes",
        isEditing: true,
        originalQuestion: null,
      },
      {
        id: 3,
        question: "What features do you like the most?",
        answerType: "multiple",
        answers: ["Design", "Handling", "Layout", "Ease of Use"],
        isEditing: true,
        originalQuestion: null,
      },
      {
        id: 4,
        question: "How would you rate the value for money of this product?",
        answerType: "rating",
        answers: "5",
        isEditing: true,
        originalQuestion: null,
      },
      {
        id: 5,
        question: "What would you improve in this product?",
        answerType: "text",
        answers: "Add more color options",
        isEditing: true,
        originalQuestion: null,
      },
    ],
  },
  {
    surveyId: 5,
    title: "Customer Support Experience Survey",
    status: "Draft",
    description: "A survey to evaluate customer support interactions.",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with the support you received?",
        answerType: "rating",
        answers: "5",
        isEditing: true,
        originalQuestion: null,
      },
      {
        id: 2,
        question: "Did the support team resolve your issue effectively?",
        answerType: "yesno",
        answers: "Yes",
        isEditing: true,
        originalQuestion: null,
      },
      {
        id: 3,
        question: "How easy was it to reach customer support?",
        answerType: "multiple",
        answers: ["Phone", "Email", "Chat", "Website"],
        isEditing: true,
        originalQuestion: null,
      },
      {
        id: 4,
        question: "How would you rate the professionalism of the support team?",
        answerType: "rating",
        answers: "5",
        isEditing: true,
        originalQuestion: null,
      },
      {
        id: 5,
        question: "What suggestions do you have for improving support?",
        answerType: "text",
        answers: "Faster response time",
        isEditing: true,
        originalQuestion: null,
      },
    ],
  },
];

const SurveyForm = () => {
  const { surveyId } = useParams();
  const { user, loginAsGuest } = useAuth();
  const [surveyData, setSurveyData] = useState();

  // // Handle anonymous access
  // const startAnonymousSurvey = () => {
  //   loginAsGuest();
  // };

  // if (!user) {
  //   return (
  //     <Container>
  //       <Box
  //         sx={{
  //           mt: 8,
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "center",
  //         }}
  //       >
  //         <Typography variant="h3" gutterBottom>
  //           Survey Response
  //         </Typography>
  //         <Typography variant="h6" gutterBottom>
  //           Choose how you'd like to proceed:
  //         </Typography>
  //         <Box sx={{ mt: 4 }}>
  //           <Button
  //             variant="contained"
  //             color="primary"
  //             onClick={startAnonymousSurvey}
  //             sx={{ mr: 2 }}
  //           >
  //             Anonymously
  //           </Button>
  //           <Button variant="outlined" color="primary" onClick={() => {}}>
  //             With Name
  //           </Button>
  //         </Box>
  //       </Box>
  //     </Container>
  //   );
  // }

  useEffect(() => {
    if (!user) {
      loginAsGuest();
    }
    const decodedString = atob(surveyId).split("_")[1];
    const filterSurvey = initialSurveys.filter(
      (survey) => survey.surveyId === Number(decodedString)
    )[0];
    console.log(filterSurvey);
    setSurveyData(filterSurvey);
  }, [user, surveyId]);

  const handleSurveySubmit = (answers) => {
    // Handle survey submission logic here and send these answers to a backend API
    console.log("Survey Answers:", answers);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {surveyData ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paper
            sx={{
              p: 3,
              mb: 3,
              boxShadow: 3,
              position: "relative",
            }}
          >
            <RenderSurvey data={surveyData} onSubmit={handleSurveySubmit} />
          </Paper>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
          }}
        >
          <CircularProgress size="3rem" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Loading....
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default SurveyForm;
