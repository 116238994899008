import React, { useState } from "react";
import {
  Button,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Box,
  Container,
  Grid,
  Stack,
  InputAdornment,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { Business, Flag, Language, LocationCity, Map, Phone, PinDrop, Place } from "@mui/icons-material";

const CompanyOnboarding = () => {
  const navigate = useNavigate()
  const [COUNTRY, setCOUNTRY] = useState([{id: 1, country_name: 'India', country_code: '+91'}]);
  const [STATE, setSTATE] = useState([{id: 1, state_name: 'UttarPradesh', state_code: '9'}]);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const regFormik = useFormik({
    initialValues: {
      organization_name: "",
      website_url: "",
      organization_logo: "",
      phone_no: "",
      address_line_1: "",
      address_line_2: "",
      country: 1,
      state: "",
      city: "",
      zip_code: "",
    },
    validationSchema: Yup.object({
      organization_name: Yup.string()
        .max(255)
        .required("Company Name is required"),
      website_url: Yup.string().max(255),
      phone_no: Yup.string()
        .required("Phone Number is required")
        .matches(phoneRegExp, "Phone number is not valid"),
      state: Yup.string().max(255).required("State is required"),
      country: Yup.string().max(255).required("Country is required"),
      address_line_1: Yup.string()
        .max(255)
        .required("Address Line1 is required"),
      city: Yup.string().max(255).required("City is required"),
      zip_code: Yup.string().max(255).required("Zip Code is required"),
    }),
    onSubmit: async (values, helpers) => {
      navigate('/dashboard')
    },
  });

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 6, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Stack spacing={1} sx={{ mb: 3 }}>
            <Typography variant="h4" textAlign={'center'} gutterBottom>Company OnBoarding</Typography>
            <Typography color="text.secondary" variant="body2">
              Already have an account? &nbsp;
              <Link to="/login" underline="hover" variant="subtitle2">
                Sign In
              </Link>
            </Typography>
          </Stack>
          <Box width={"100%"}>
            <form noValidate onSubmit={regFormik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.organization_name &&
                        regFormik.errors.organization_name
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.organization_name &&
                      regFormik.errors.organization_name
                    }
                    label="Organization Name"
                    name="organization_name"
                    onBlur={regFormik.handleBlur}
                    type="text"
                    onChange={regFormik.handleChange}
                    value={regFormik.values.organization_name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Business />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.website_url &&
                        regFormik.errors.website_url
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.website_url &&
                      regFormik.errors.website_url
                    }
                    label="Website URL"
                    name="website_url"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    value={regFormik.values.website_url}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Language />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.phone_no && regFormik.errors.phone_no
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.phone_no && regFormik.errors.phone_no
                    }
                    label="Phone Number"
                    name="phone_no"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    value={regFormik.values.phone_no}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.address_line_1 &&
                        regFormik.errors.address_line_1
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.address_line_1 &&
                      regFormik.errors.address_line_1
                    }
                    label="Address 1"
                    name="address_line_1"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    value={regFormik.values.address_line_1}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Place />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.address_line_2 &&
                        regFormik.errors.address_line_2
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.address_line_2 &&
                      regFormik.errors.address_line_2
                    }
                    label="Address 2"
                    name="address_line_2"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    value={regFormik.values.address_line_2}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Place />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(regFormik.touched.country && regFormik.errors.country)
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.country && regFormik.errors.country
                    }
                    label="Country"
                    name="country"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    select
                    value={regFormik.values.country}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Flag />
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem>select</MenuItem>
                    {COUNTRY &&
                      COUNTRY.map((item, ind) => (
                        <MenuItem
                          key={ind}
                          value={item.id}
                        >{`${item.country_name} (${item.country_code})`}</MenuItem>
                      ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(regFormik.touched.state && regFormik.errors.state)
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.state && regFormik.errors.state
                    }
                    label="State"
                    name="state"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    select
                    value={regFormik.values.state}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Map />
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem>select</MenuItem>
                    {STATE &&
                      STATE.map((item, ind) => (
                        <MenuItem
                          key={ind}
                          value={item.id}
                        >{`${item.state_name} (${item.state_code})`}</MenuItem>
                      ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={!!(regFormik.touched.city && regFormik.errors.city)}
                    fullWidth
                    helperText={regFormik.touched.city && regFormik.errors.city}
                    label="City"
                    name="city"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    value={regFormik.values.city}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationCity />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.zip_code && regFormik.errors.zip_code
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.zip_code && regFormik.errors.zip_code
                    }
                    label="Zip Code"
                    name="zip_code"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="number"
                    value={regFormik.values.zip_code}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PinDrop />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.organization_logo &&
                        regFormik.errors.organization_logo
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.organization_logo &&
                      regFormik.errors.organization_logo
                    }
                    label="Organization Logo"
                    name="organization_logo"
                    type="file"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    value={regFormik.values.organization_logo}
                  />
                </Grid>
              </Grid>
              {regFormik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {regFormik.errors.submit}
                </Typography>
              )}
              <Box mt={2}>
                <Typography variant="caption">*Indicate Required</Typography>
              </Box>

              <Box display="flex" alignContent="center" justifyContent="center">
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default CompanyOnboarding;
