import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputAdornment,
  FormControlLabel,
  FormGroup,
  Checkbox,
  ButtonGroup,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Popper,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
  Link as LinkIcon,
  Search as SearchIcon,
  Groups2 as Groups2Icon,
  Leaderboard as LeaderBoardIcon,
  CalendarMonth as CalendarMonthIcon,
} from "@mui/icons-material";
import Toast from "../Tost";
import { useNavigate, useParams } from "react-router-dom";
import { CustomTable } from "../TableComponents/CustomTable";
import NormalCell from "../TableComponents/TableCells/NormalCell";
import ActionCell from "../TableComponents/TableCells/ActionCell";
import ShowMoreLessCell from "../TableComponents/TableCells/ShowMoreLessCell";
import DateCell from "../TableComponents/TableCells/DateCell";
import StatusCell from "../TableComponents/TableCells/StatusCell";
import Model from "../Model";
import { format } from "date-fns";

const initialSurveys = [
  {
    surveyId: 1,
    title: "Customer Feedback Survey",
    status: "published",
    is_anonymous: false,
    description:
      "A survey to collect customer feedback on our products. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe sint pariatur ipsum, quam, blanditiis quod voluptates sapiente laudantium iure reiciendis placeat incidunt hic nisi fuga vitae tempore repudiandae doloremque repellendus.",
    published_date: "2024-11-25",
    updated_date: "2024-11-30",
    started_on: "",
    ends_on: "",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with our product?",
        answerType: "rating",
        answers: "3",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-25",
      },
      {
        id: 2,
        question: "Would you recommend our product to others?",
        answerType: "yesno",
        answers: "Yes",
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-26",
      },
    ],
  },
  {
    surveyId: 3,
    title: "Employee Satisfaction Survey",
    status: "paused",
    is_anonymous: false,
    description: "A survey to gauge employee satisfaction and engagement.",
    published_date: "2024-11-20",
    updated_date: "",
    started_on: "2024-11-21",
    ends_on: "2024-12-04",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with your work environment?",
        answerType: "rating",
        answers: "3",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-20",
      },
      {
        id: 2,
        question: "Would you recommend this company as a great place to work?",
        answerType: "yesno",
        answers: "No",
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-21",
      },
      {
        id: 3,
        question: "Which benefits do you find most valuable?",
        answerType: "multiple",
        answers: [
          "Health Insurance",
          "Paid Time Off",
          "Health Insurance",
          "Paid Time Off",
        ],
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-24",
      },
      {
        id: 4,
        question: "How would you rate communication within your team?",
        answerType: "rating",
        answers: "4",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-24",
      },
      {
        id: 5,
        question:
          "What improvements would you suggest for the work environment?",
        answerType: "text",
        answers: "More team-building activities",
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-26",
      },
      {
        id: 6,
        question:
          "What improvements would you suggest for the work environment?",
        answerType: "text",
        answers: "More team-building activities",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
      {
        id: 7,
        question:
          "What improvements would you suggest for the work environment?",
        answerType: "text",
        answers: "More team-building activities",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
    ],
  },
  {
    surveyId: 4,
    title: "Product Feedback Survey",
    status: "draft",
    is_anonymous: true,
    description: "A survey to gather feedback on a new product release.",
    published_date: "2024-10-28",
    updated_date: "2024-11-02",
    started_on: "",
    ends_on: "",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with the new product?",
        answerType: "rating",
        answers: "4",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-25",
      },
      {
        id: 2,
        question: "Would you buy this product again?",
        answerType: "yesno",
        answers: "Yes",
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-25",
      },
      {
        id: 3,
        question: "What features do you like the most?",
        answerType: "multiple",
        answers: ["Design", "Ease of Use", "Design", "Ease of Use"],
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
      {
        id: 4,
        question: "How would you rate the value for money of this product?",
        answerType: "rating",
        answers: "5",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
      {
        id: 5,
        question: "What would you improve in this product?",
        answerType: "text",
        answers: "Add more color options",
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-27",
      },
    ],
  },
  {
    surveyId: 5,
    title: "Customer Support Experience Survey",
    status: "closed",
    is_anonymous: false,
    description: "A survey to evaluate customer support interactions.",
    published_date: "2024-10-10",
    updated_date: "",
    started_on: "",
    ends_on: "",
    questions: [
      {
        id: 1,
        question: "How satisfied are you with the support you received?",
        answerType: "rating",
        answers: "5",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
      {
        id: 2,
        question: "Did the support team resolve your issue effectively?",
        answerType: "yesno",
        answers: "Yes",
        isEditing: true,
        originalQuestion: null,
        status: "disabled",
        date: "2024-11-26",
      },
      {
        id: 3,
        question: "How easy was it to reach customer support?",
        answerType: "multiple",
        answers: ["Phone", "Email", "Chat", "Website"],
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
      {
        id: 4,
        question: "How would you rate the professionalism of the support team?",
        answerType: "rating",
        answers: "5",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
      {
        id: 5,
        question: "What suggestions do you have for improving support?",
        answerType: "text",
        answers: "Faster response time",
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
    ],
  },
  {
    surveyId: 6,
    title: "New Survey",
    status: "draft",
    is_anonymous: false,
    description: "Testing Survey",
    published_date: "2024-12-02",
    updated_date: "",
    started_on: "",
    ends_on: "",
    questions: [],
  },
];

const formatDate = (date) => {
  return format(new Date(date), "dd-MMM-yyyy");
};

const SurveyDetails = () => {
  const { surveyId } = useParams();
  const navigate = useNavigate();
  const [survey, setSurvey] = useState({
    title: "Customer Satisfaction Survey",
    status: "Draft",
    description: "A survey to gather customer feedback about our services.",
    questions: [
      {
        id: 1,
        question: "",
        answerType: "text",
        answers: [],
        isEditing: true,
        originalQuestion: null,
        status: "enabled",
        date: "2024-11-26",
      },
    ],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const decodedString = atob(surveyId).split("_")[0];
    const filterSurvey = initialSurveys.filter(
      (survey) => survey.surveyId === Number(decodedString)
    )[0];
    setSurvey(filterSurvey);
    setRows(filterSurvey?.questions);
  }, [surveyId]);

  const questionTypes = [
    { value: "text", label: "Text Answer" },
    { value: "number", label: "Numeric Answer" },
    { value: "multiple", label: "Multiple Choice (Multiple Answers)" },
    { value: "single", label: "Multiple Choice (Single Answer)" },
    { value: "rating", label: "Rating Scale" },
    { value: "yesno", label: "Yes/No" },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [answerTypeFilter, setAnswerTypeFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredSurveyQuestions, setFilteredSurveyQuestions] = useState([]);
  const [openQestionModel, setOpenQestionModel] = useState(false);
  const [openEditQuestion, setOpenEditQuestion] = useState(false);
  const [openEditSurveyModel, setOpenEditSurveyModel] = useState(false);
  const [question, setQuestion] = useState("");
  const [answerType, setAnswerType] = useState("");
  const [answerOptions, setAnswerOptions] = useState([""]);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [radioValue, setRadioValue] = useState("named_survey");
  const respondentDetails = [
    "Respondent Name",
    "Email",
    "Phone Number",
    "Gender",
  ];
  const [selectedRespondentDetailsAns, setSelectedRespondentDetailsAns] =
    useState([]);
  const [filePath, setFilePath] = useState("");
  const [editingQuestionId, setEditingQuestionId] = useState();
  const [open, setOpen] = useState(false);
  const shareAnchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const options = [
    {
      label: "Copy weblink",
      action: "Copy weblink",
      icon: <LinkIcon fontSize="small" />,
    },
    {
      label: "Share via email",
      action: "Share via email",
      icon: <EmailIcon fontSize="small" />,
    },
  ];
  const [surveyLink, setSurveyLink] = useState("");
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);

  const handleClick = () => {
    if (options[selectedIndex].action === "Copy weblink") {
      const link = generateSurveyLink(surveyId);
      setSurveyLink(link);
      setLinkDialogOpen(true);
    } else if (options[selectedIndex].action === "Share via email") {
      navigate("/email-invitation");
    }
    setOpen(false);
  };

  const generateSurveyLink = (surveyId) => {
    const baseUrl =
      survey.status === "Anonymous"
        ? "http://localhost:3000/feedback-form"
        : "http://localhost:3000/feedback-login-form";
    const uniqueId = btoa(`survey_${surveyId}_${Date.now()}`);
    return `${baseUrl}/survey/${uniqueId}`;
  };

  const copyLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(surveyLink);
      setToastMessage("Link copied to clipboard!");
      setToastType("success");
      setToast(true);
    } catch (err) {
      setToastMessage("Failed to copy link");
      setToastType("error");
      setToast(true);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    if (options[index].action === "Copy weblink") {
      const link = generateSurveyLink(surveyId);
      setSurveyLink(link);
      setLinkDialogOpen(true);
    } else if (options[index].action === "Share via email") {
      navigate("/email-invitation");
    }

    setOpen(false);
  };

  const handleLinkDialogClose = () => {
    setLinkDialogOpen(false);
    setSurveyLink("");
  };

  const renderLinkDialog = () => (
    <Dialog open={linkDialogOpen} onClose={handleLinkDialogClose} maxWidth="md">
      <DialogTitle sx={{ fontWeight: "bold" }}>Survey Link</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Share this link to allow people to fill out your survey:
          </Typography>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              gap: 2,
              bgcolor: "grey.50",
            }}
          >
            <TextField
              fullWidth
              value={surveyLink}
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              variant="contained"
              startIcon={<ContentCopyIcon />}
              onClick={copyLinkToClipboard}
            >
              Copy
            </Button>
          </Paper>
        </Box>
        <Typography variant="body2" color="text.secondary">
          This link will be available until you delete or unpublish the survey.
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2 }}>
        <Button onClick={handleLinkDialogClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      shareAnchorRef.current &&
      shareAnchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleAnswerTypeFilterChange = (event) => {
    setAnswerTypeFilter(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setPage(0);
  };

  const handleReorderedRows = (reorderedRows) => {
    // Handle the reordered rows, e.g., update your data source
    setRows(reorderedRows);
  };

  const handleChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleDeleteQuestion = (questionId) => {
    const filteredData = rows?.filter((q) => q.id !== questionId);
    setRows(filteredData);
    setSurvey({
      ...survey,
      questions: rows,
    });
    setToast(true);
    setToastMessage("Question deleted successfully");
    setToastType("success");
    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  const handleEditQuestion = (data) => {
    setQuestion(data.question);
    setAnswerType(data.answerType);

    // Prepare answer options based on answer type
    switch (data.answerType) {
      case "multiple":
      case "single":
        // Use existing answer options or reset to empty
        setAnswerOptions(data.answers.length > 0 ? data.answers : [""]);
        break;
      case "rating":
        setAnswerOptions(["1", "2", "3", "4", "5"]);
        break;
      case "yesno":
        setAnswerOptions(["Yes", "No"]);
        break;
      default:
        setAnswerOptions([""]);
    }
    setOpenEditQuestion(true);
    setOpenQestionModel(true);
    setEditingQuestionId(data.id);
  };

  const handleAddAnswerOption = () => {
    setAnswerOptions([...answerOptions, ""]);
  };

  // Function to update a specific answer option
  const handleAnswerOptionChange = (index, value) => {
    const newOptions = [...answerOptions];
    newOptions[index] = value;
    setAnswerOptions(newOptions);
  };

  // Function to remove an answer option
  const handleRemoveAnswerOption = (index) => {
    const newOptions = answerOptions.filter((_, i) => i !== index);
    setAnswerOptions(newOptions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let preparedAnswers = [];
    switch (answerType) {
      case "multiple":
      case "single":
        // Use the user-defined answer options
        preparedAnswers = answerOptions.filter(
          (option) => option.trim() !== ""
        );
        break;
      case "rating":
        preparedAnswers = ["1", "2", "3", "4", "5"];
        break;
      case "yesno":
        preparedAnswers = ["Yes", "No"];
        break;
      default:
        preparedAnswers = [];
    }

    // Create new question object
    const newQuestion = {
      id: editingQuestionId || Date.now(),
      question: question,
      answerType: answerType,
      answers: preparedAnswers,
      status: "enabled",
      date: `${new Date().toISOString().split("T")[0]}`,
      isEditing: true,
      originalQuestion: null,
    };

    const updatedRows = editingQuestionId
      ? rows.map((q) => (q.id === editingQuestionId ? newQuestion : q))
      : [...rows, newQuestion];

    // Add the new question to rows
    setRows(updatedRows);

    // Reset form states
    setQuestion("");
    setAnswerType("");
    setAnswerOptions([""]);
    setOpenQestionModel(false);
    setEditingQuestionId(null);
  };

  const handleSubmitSurveyDetails = (e) => {
    e.preventDefault();
    setOpenEditSurveyModel(false);
  };

  const handleRespDetailCheckboxChange = (option) => {
    setSelectedRespondentDetailsAns((prev) =>
      prev.includes(option)
        ? prev.filter((answer) => answer !== option)
        : [...prev, option]
    );
  };

  useEffect(() => {
    const filteredQuestions = rows?.filter((question) => {
      const matchesSearch = question.question
        .toLowerCase()
        .includes(searchQuery);
      const matchesStatus =
        statusFilter === "" || question.status === statusFilter;
      const matchesAnswerType =
        answerTypeFilter === "" || question.answerType === answerTypeFilter;
      return matchesSearch && matchesStatus && matchesAnswerType;
    });
    setFilteredSurveyQuestions(filteredQuestions);
  }, [rows, searchQuery, statusFilter, answerTypeFilter]);

  const headers = [
    {
      name: "serial_no",
      displayName: "S.No",
      width: `${100 / 20}%`,
      cell: ({ row }) => <NormalCell row={{ ...row, text: row.id }} />,
    },
    {
      name: "question",
      displayName: "Question",
      width: `${100 / 6}%`,
      cell: ({ row }) => (
        <ShowMoreLessCell row={{ ...row, text: row.question }} />
      ),
    },
    {
      name: "answerType",
      displayName: "Answer Type",
      width: `${100 / 6}%`,
      cell: ({ row }) => <NormalCell row={{ ...row, text: row.answerType }} />,
    },
    {
      name: "date",
      displayName: "Updated Date",
      width: `${100 / 6}%`,
      cell: ({ row }) => <DateCell row={{ ...row, text: row.date }} />,
    },
    {
      name: "status",
      displayName: "Status",
      width: `${100 / 6}%`,
      cell: ({ row }) => <StatusCell row={{ ...row, text: row.status }} />,
    },
    {
      name: "actions",
      displayName: "Actions",
      width: `${100 / 6}%`,
      cell: ({ row }) => (
        <ActionCell
          row={{ ...row }}
          handleDeleteQuestion={handleDeleteQuestion}
          handleEditQuestion={handleEditQuestion}
        />
      ),
    },
  ];

  const handleActions = ({ row }) => (
    <div>
      <button onClick={() => console.log("Edit", row)}>Edit</button>
      <button onClick={() => console.log("Delete", row)}>Delete</button>
    </div>
  );

  return (
    <Container maxWidth="fuild" sx={{ mt: 6 }}>
      <Box sx={{ px: 4 }}>
        <Stack direction="row" justifyContent="end" gap={1} mb={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<VisibilityIcon />}
            sx={{ fontSize: 11 }}
            onClick={() => {
              const baseUrl = "http://localhost:3000/survey-preview";
              const uniqueId = btoa(`survey_${surveyId}_${Date.now()}`);
              const newWindowUrl = `${baseUrl}/${uniqueId}`;

              window.open(newWindowUrl, "_blank");
            }}
          >
            Preview Survey
          </Button>

          <ButtonGroup
            variant="contained"
            ref={shareAnchorRef}
            aria-label="Button group with a nested menu"
          >
            <Button
              sx={{ fontSize: 11 }}
              onClick={handleClick}
              startIcon={options[selectedIndex].icon}
            >
              {options[selectedIndex].label}
            </Button>
            <Button
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{ zIndex: 1 }}
            open={open}
            anchorEl={shareAnchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option.action}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          <ListItemIcon>{option.icon}</ListItemIcon>
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          {renderLinkDialog()}
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Groups2Icon />}
            sx={{ fontSize: 11 }}
            onClick={() => navigate(`/survey-responses/${surveyId}`)}
          >
            Responses
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<LeaderBoardIcon />}
            sx={{ fontSize: 11 }}
            onClick={() => navigate("/anaylze-responses")}
          >
            Analysis
          </Button>
        </Stack>
        <Box sx={{ mb: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="left"
              gap={1}
            >
              <Typography variant="h4" component="h5">
                <strong>Survey Title: </strong>
                {survey.title}
                {survey.is_anonymous && (
                  <Chip
                    label="Anonymous"
                    sx={{
                      ml: 1,
                      backgroundColor: "purple",
                      color: "#fff",
                      fontSize: "1rem",
                    }}
                  />
                )}
                <Chip
                  label={survey.status}
                  sx={{
                    textTransform: 'capitalize',
                    ml: 1,
                    backgroundColor:
                      survey.status === "published"
                        ? "green"
                        : survey.status === "draft"
                        ? "gray"
                        : survey.status === "paused"
                        ? "#FFC107"
                        : "red",
                    color: "#fff",
                    fontSize: "1rem",
                  }}
                />
              </Typography>
              <IconButton
                onClick={() => setOpenEditSurveyModel(true)}
                color="info"
              >
                <EditIcon />
              </IconButton>
              <Model
                header
                headerText="Edit Survey Details"
                open={openEditSurveyModel}
                close
                handleClose={() => setOpenEditSurveyModel(false)}
                handleSubmit={handleSubmitSurveyDetails}
                resetForm={() => {}}
                loading={loading}
                footer
                customWidth={true}
              >
                <Stack spacing={3}>
                  {/* Survey Type Section */}
                  <FormControl>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Survey Type
                    </Typography>
                    <RadioGroup
                      aria-labelledby="survey-type-radio-buttons-group"
                      name="survey-type"
                      value={radioValue}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="named_survey"
                        control={<Radio />}
                        label={
                          <Typography variant="body1">
                            <strong>Named Survey</strong> (Respondent needs to
                            share personal details before starting.)
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="anonymous"
                        control={<Radio />}
                        label={
                          <Typography variant="body1">
                            <strong>Anonymous</strong> (Respondent details will
                            not be collected at all.)
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>

                  {/* Respondent Details Section */}
                  {radioValue === "anonymous" && (
                    <FormGroup>
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Respondent Details to Be Collected
                      </Typography>
                      {respondentDetails.map((option, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={selectedRespondentDetailsAns.includes(
                                option
                              )}
                              onChange={() =>
                                handleRespDetailCheckboxChange(option)
                              }
                            />
                          }
                          label={option}
                        />
                      ))}
                    </FormGroup>
                  )}

                  {/* Add Logo Section */}
                  <Box>
                    <Typography variant="h6" fontWeight="bold">
                      Add Logo
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: "text.secondary" }}
                    >
                      (This logo will appear on the top of the survey page and
                      can be used as a company branding.)
                    </Typography>
                    <TextField
                      id="file_path"
                      accept="image/doc,pdf,jpeg,jpg,png"
                      label="Upload File"
                      name="file_path"
                      type="file"
                      onChange={(e) => setFilePath(e.target.value)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      sx={{ mt: 1 }}
                    />
                  </Box>

                  {/* Survey Details Section */}
                  <Box>
                    <Typography variant="h6" fontWeight="bold">
                      Survey Details
                    </Typography>
                    <TextField
                      label="Survey Title"
                      fullWidth
                      value={survey.title}
                      onChange={(e) =>
                        setSurvey({ ...survey, title: e.target.value })
                      }
                      required
                      sx={{ mt: 1 }}
                    />
                    <TextField
                      label="Survey Description"
                      fullWidth
                      multiline
                      rows={3}
                      value={survey.description}
                      onChange={(e) =>
                        setSurvey({
                          ...survey,
                          description: e.target.value,
                        })
                      }
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
              </Model>
            </Box>
          </Stack>
          <Typography variant="subtitle1">
            <strong style={{ color: "#232946" }}>Description: </strong>
            {survey.description}
          </Typography>
          <Stack direction={"row"} alignItems={"center"} spacing={0.6}>
            <CalendarMonthIcon style={{ width: "16px", height: "16px" }} />
            <Typography variant="subtitle1">
              {survey?.updated_date ? (
                <>
                  <strong>Last Updated Date:</strong>{" "}
                  {survey?.updated_date
                    ? formatDate(survey?.updated_date)
                    : "--"}
                </>
              ) : (
                <>
                  <strong>Published Date:</strong>{" "}
                  {survey?.published_date
                    ? formatDate(survey?.published_date)
                    : "--"}
                </>
              )}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle1">
              <strong>Started On:</strong>{" "}
              {survey?.started_on
                ? formatDate(survey?.started_on)
                : survey?.published_date && formatDate(survey?.published_date)}
            </Typography>
            {survey?.ends_on && (
              <Typography variant="subtitle1">
                <strong>Ends On:</strong>{" "}
                {survey?.ends_on && formatDate(survey?.ends_on)}
              </Typography>
            )}
          </Stack>
        </Box>

        <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Question"
            value={searchQuery}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearchChange}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Answer Type</InputLabel>
            <Select
              value={answerTypeFilter}
              onChange={handleAnswerTypeFilterChange}
              label="Answer Type"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="text">Text Answer</MenuItem>
              <MenuItem value="number">Numeric Answer</MenuItem>
              <MenuItem value="multiple">
                Multiple Choice (Multiple Answers)
              </MenuItem>
              <MenuItem value="single">
                Multiple Choice (Single Answer)
              </MenuItem>
              <MenuItem value="rating">Rating Scale</MenuItem>
              <MenuItem value="yesno">Yes/No</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={statusFilter}
              onChange={handleStatusFilterChange}
              label="Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="enabled">Enabled</MenuItem>
              <MenuItem value="disabled">Disabled</MenuItem>
            </Select>
          </FormControl>
          <Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              sx={{ fontSize: 11, height: "100%" }}
              onClick={() => {
                setOpenQestionModel(true);
                setQuestion("");
                setAnswerType("");
                setAnswerOptions([""]);
                setOpenEditQuestion(false);
                setEditingQuestionId(null);
              }}
            >
              Add Question
            </Button>

            <Model
              header
              headerText={openEditQuestion ? "Edit Question" : "Add Question"}
              open={openQestionModel}
              close
              handleClose={() => setOpenQestionModel(false)}
              handleSubmit={handleSubmit}
              resetForm={() => {}}
              loading={loading}
              footer
              customWidth={true}
            >
              <Stack spacing={2}>
                <TextField
                  label="Question"
                  fullWidth
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  required
                  variant="outlined"
                />
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Question Type</InputLabel>
                  <Select
                    value={answerType}
                    label="Question Type"
                    onChange={(e) => setAnswerType(e.target.value)}
                  >
                    {questionTypes.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {(answerType === "multiple" || answerType === "single") && (
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Answer Options
                    </Typography>
                    {answerOptions.map((option, index) => (
                      <Stack
                        key={index}
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        sx={{ mb: 2 }}
                      >
                        <TextField
                          fullWidth
                          label={`Option ${index + 1}`}
                          value={option}
                          onChange={(e) =>
                            handleAnswerOptionChange(index, e.target.value)
                          }
                        />
                        {answerOptions.length > 1 && (
                          <IconButton
                            color="error"
                            onClick={() => handleRemoveAnswerOption(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                    ))}
                    <Button
                      startIcon={<AddIcon />}
                      onClick={handleAddAnswerOption}
                      variant="outlined"
                    >
                      Add Option
                    </Button>
                  </Box>
                )}
              </Stack>
            </Model>
          </Box>
        </Stack>

        <CustomTable
          count={filteredSurveyQuestions.length}
          headers={headers}
          rows={filteredSurveyQuestions}
          onRowReorder={handleReorderedRows}
          enableRowReorder={true}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          handleActions={handleActions}
          listLoading={loading}
          emptyRecordText={"No questions has been found."}
        />
        {toast && (
          <Toast
            setToast={setToast}
            isLoading={isLoading}
            toast={toast}
            toastType={toastType}
            toastMessage={toastMessage}
          />
        )}
      </Box>
    </Container>
  );
};

export default SurveyDetails;
