import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const loginAsGuest = () => {
    const guestUser = {
      id: 'guest',
      roles: ['guest'],
      isAnonymous: true
    };
    setUser(guestUser);
    localStorage.setItem('user', JSON.stringify(guestUser));
  }

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  const hasPermission = (requiredRole) => {
    if (user?.isAnonymous && requiredRole === 'guest') {
      return true;
    }
    return user && user.roles.includes(requiredRole);
  };

  return (
    <AuthContext.Provider value={{ user, login, loginAsGuest, logout, hasPermission }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);