import React, { useState } from "react";
import { Button, Paper, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import RenderAnswerType from "./RenderAnswerType";
import Toast from "./Tost";
import { useNavigate } from "react-router-dom";

const RenderSurvey = ({ data, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const navigate = useNavigate();

  const questionTypes = [
    { value: "text", label: "Text Answer" },
    { value: "number", label: "Numeric Answer" },
    { value: "multiple", label: "Multiple Choice (Multiple Answers)" },
    { value: "single", label: "Multiple Choice (Single Answer)" },
    { value: "rating", label: "Rating Scale" },
    { value: "yesno", label: "Yes/No" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    // Optional: Validate all required questions are answered
    const allQuestionsAnswered = data?.questions.every(
      (question) => selectedAnswers[question.id] !== undefined
    );

    if (allQuestionsAnswered) {
      onSubmit(selectedAnswers);
      setToast(true);
      setToastMessage("Form submitting successfully.");
      setToastType("success");
      setTimeout(() => {
        setToast(false);
      }, 2000);
      navigate("/thank-you");
    } else {
      setToast(true);
      setToastMessage("Please answer all questions before submitting.");
      setToastType("error");
      setTimeout(() => {
        setToast(false);
      }, 3000);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Box mb={1}>
          <img src="/assets/logo/hr-smartApp.png" alt="Smart Survey" width={40} height={40}/>
        </Box>
        <Box>
          <Typography variant="h4" gutterBottom>
            {data?.title}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            gutterBottom
            sx={{ mb: 2 }}
          >
            {data?.description}
          </Typography>
          <Stack spacing={3} sx={{ mt: 2 }}>
            {data?.questions?.map((question) => (
              <Paper key={question.id} sx={{ p: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  {question.question}
                </Typography>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                  gutterBottom
                >
                  {
                    questionTypes.find((t) => t.value === question?.answerType)
                      ?.label
                  }
                </Typography>
                <RenderAnswerType
                  question={question}
                  selectedAnswers={selectedAnswers}
                  setSelectedAnswers={setSelectedAnswers}
                />
              </Paper>
            ))}
          </Stack>
          <Box sx={{ mt: 3, textAlign: "center" }}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </Box>
        {toast && (
          <Toast
            setToast={setToast}
            isLoading={isLoading}
            toast={toast}
            toastType={toastType}
            toastMessage={toastMessage}
          />
        )}
      </Container>
    </form>
  );
};

export default RenderSurvey;
