import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const PrivateRoute = ({ children, requiredRole, allowGuest = false }) => {
  const { user, hasPermission } = useAuth();

  if (allowGuest) {
    return children;
  }

  if (!user) {
    const currentPath = window.location.pathname;
    sessionStorage.setItem("redirectAfterLogin", currentPath);
    return <Navigate to="/login" />;
  }

  if (requiredRole && !hasPermission(requiredRole)) {
    return <Navigate to="/" />;
  }

  return children;
};

export default PrivateRoute;
