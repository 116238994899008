import {
  Autocomplete,
  Button,
  Chip,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/material";
import React, { useState } from "react";

const EmailInvitation = () => {
  const [emailData, setEmailData] = useState({
    sendTo: "",
    subject: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("send email", emailData);
  };

  const handleInputChange = (field) => (e) => {
    setEmailData((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleTagChange = (event, newValue) => {
    const validEmails = newValue.filter((email) => email);
    setEmailData((prev) => ({
      ...prev,
      sendTo: validEmails.join(","),
    }));
  };

  const handleDelete = (option) => {
    const updatedEmails = emailData.sendTo
      .split(",")
      .filter((email) => email && email !== option);
    setEmailData((prev) => ({
      ...prev,
      sendTo: updatedEmails.join(","),
    }));
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box>
        <Typography variant="h4">Email Invitation</Typography>
        <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
          <form onSubmit={handleSubmit}>
            <Typography variant="h6">Send To:</Typography>
            <Autocomplete
              clearIcon={false}
              options={[]}
              freeSolo
              multiple
              value={emailData.sendTo.split(",").filter((email) => email)}
              name="sendTo"
              onChange={handleTagChange}
              renderTags={(tagValues, getTagProps) =>
                tagValues.map((option, index) => {
                  const tagProps = getTagProps({ index });
                  return (
                    <Chip
                      key={index}
                      label={option}
                      {...tagProps}
                      onDelete={() => handleDelete(option)}
                      style={{
                        margin: "6px 4px",
                        padding: "0",
                        fontSize: "12px",
                      }}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  required
                  name="sendTo"
                  label="Recipient Email"
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
            {/* <TextField
              label="Recipient Email"
              type="email"
              value={emailData.sendTo}
              onChange={handleInputChange('sendTo')}
              fullWidth
              margin="normal"
              required
            /> */}
            <Typography variant="h6" sx={{ mt: 2 }}>
              Subject:
            </Typography>
            <TextField
              label="Email Subject"
              type="text"
              value={emailData.subject}
              onChange={handleInputChange("subject")}
              fullWidth
              margin="normal"
              required
            />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Message:
            </Typography>
            <TextField
              label="Email Message"
              value={emailData.message}
              onChange={handleInputChange("message")}
              fullWidth
              multiline
              rows={4}
              margin="normal"
              required
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 2 }}
            >
              <Button variant="contained" color="primary" type="submit">
                Send
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default EmailInvitation;
