import { Box, Container } from "@mui/system";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import SurveyForm from "./SurveyForm";

const SurveyFormFillHandle = () => {
  const { surveyId } = useParams();
  const { user } = useAuth();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [genderValue, setGenderValue] = useState("male");
  const navigate = useNavigate();

  const handleSubmit = () => {
    const respondentDetails = {
      name: name,
      email: email,
      phone_no: phoneNo,
      gender: genderValue,
    };
    console.log("respondentDetails", respondentDetails);
    navigate(`/feedback-form/survey/${surveyId}`);
  };

  if (!user) {
    return (
      <Container maxWidth="md">
        <Box
          sx={{
            mt: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paper elevation={3} sx={{ p: 4, maxWidth: 400 }}>
            <Typography variant="h4" textAlign={"center"} gutterBottom>
              Respondent Details
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                margin="normal"
                label="Your Name"
                type="text"
                value={name}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                type="email"
                value={email}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Phone Number"
                type="text"
                value={phoneNo}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setPhoneNo(e.target.value)}
              />
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Gender
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={genderValue}
                  onChange={(e) => setGenderValue(e.target.value)}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mt: 2 }}
              >
                Sumbit
              </Button>
            </form>
          </Paper>
        </Box>
      </Container>
    );
  }

  return <SurveyForm surveyId={surveyId} />;
};

export default SurveyFormFillHandle;
