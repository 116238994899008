import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const ActionButtonCell = ({ row, buttonLabel, buttonIcon }) => {
  return (
    <Box>
      <Button variant="contained" size="small" sx={{fontSize: 11}} startIcon={buttonIcon}>
        {buttonLabel}
      </Button>
    </Box>
  );
};

export default ActionButtonCell;
