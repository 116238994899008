import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const ProgressCell = ({row}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          width: "100px",
          height: "8px",
          bgcolor: "#e0e0e0",
          borderRadius: "4px",
          mr: 1,
        }}
      >
        <Box
          sx={{
            width: `${row?.progress ? row.progress : 0}%`,
            height: "100%",
            bgcolor: "primary.main",
            borderRadius: "4px",
          }}
        />
      </Box>
      <Typography variant="caption">{row?.progress ? row.progress : 0}%</Typography>
    </Box>
  );
};

export default ProgressCell;
