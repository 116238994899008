import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import SurveyList from "../survey/SurveyList";
import SurveyAnalytics from "./SurveyAnalytics";

const SurveyDashboard = () => {
  const { user } = useAuth();

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        {`Welcome, ${user.firstName} ${user.lastName}`}
      </Typography>
      <Grid container spacing={3}>
        {user.roles.includes("admin") && (
          <Grid item xs={12}>
            <SurveyAnalytics />
          </Grid>
        )}
        <Grid item xs={12}>
          <SurveyList />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SurveyDashboard;
