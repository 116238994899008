import { Typography } from "@mui/material";
import React from "react";
const NormalCell = ({ row, type }) => {
  return (
    <Typography textTransform={"capitalize"} fontSize="15px">
      {type === "number" ? row.text || "0" : row.text || "--"}
    </Typography>
  );
};

export default NormalCell;
