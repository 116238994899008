import React from 'react'
import { format } from 'date-fns'

const formatDate = (date) => {
  return format(new Date(date), 'dd-MMM-yyyy')
}

const DateCell = ({ row }) => {
  return <div>{`${row.text ? formatDate(`${row.text}`) : '--'}`}</div>
}

export default DateCell
