import { Button, CircularProgress } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const CustomButtom = ({
  fullWidth,
  type,
  loading,
  disabled,
  variant,
  color,
  text,
  size = "small",
  sx = {},
  onClick = () => {},
}) => {
  return (
    <Button
      fullWidth={fullWidth}
      type={type}
      disabled={disabled}
      variant={variant}
      color={color}
      size={size}
      sx={sx}
      onClick={onClick}
    >
      {text}
      {loading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
          }}
        >
          <CircularProgress
            style={{ fontWeight: 1000 }}
            size={20}
            color="inherit"
          />
        </div>
      )}
    </Button>
  );
};

CustomButtom.propTypes = {
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  sx: PropTypes.object,
};
