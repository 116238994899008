import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const SurveyAnalysis = ({ surveyData }) => {
  const [selectedMultipleChoiceQuestion, setSelectedMultipleChoiceQuestion] =
    useState(null);
  const [selectedRatingQuestion, setSelectedRatingQuestion] = useState(null);

  // Filter questions by type
  const multipleChoiceQuestions = useMemo(
    () => surveyData[0]?.questions.filter((q) => q.type === "multiple-choice"),
    [surveyData]
  );

  const ratingQuestions = useMemo(
    () => surveyData[0]?.questions.filter((q) => q.type === "rating"),
    [surveyData]
  );

  // Aggregate Multiple Choice Data
  const aggregateMultipleChoice = useCallback(
    (questionId) => {
      const question = surveyData[0]?.questions.find(
        (q) => q.id === questionId
      );
      if (!question) return { labels: [], data: [] };

      const counts = question.responses.reduce((acc, response) => {
        acc[response] = (acc[response] || 0) + 1;
        return acc;
      }, {});

      return {
        labels: Object.keys(counts),
        data: Object.values(counts),
        question: question.question,
      };
    },
    [surveyData]
  );

  // Aggregate Rating Data
  const aggregateRatings = useCallback(
    (questionId) => {
      const question = surveyData[0]?.questions.find(
        (q) => q.id === questionId
      );
      if (!question) return { labels: [], data: [] };

      const counts = question.responses.reduce((acc, rating) => {
        acc[rating] = (acc[rating] || 0) + 1;
        return acc;
      }, {});

      const labels = Object.keys(counts).sort((a, b) => a - b);
      const data = labels.map((label) => counts[label]);

      return {
        labels,
        data,
        question: question.question,
      };
    },
    [surveyData]
  );

  // Ensure a default question is selected when component mounts
  useEffect(() => {
    if (
      multipleChoiceQuestions?.length > 0 &&
      !selectedMultipleChoiceQuestion
    ) {
      setSelectedMultipleChoiceQuestion(multipleChoiceQuestions[0].id);
    }
    if (ratingQuestions?.length > 0 && !selectedRatingQuestion) {
      setSelectedRatingQuestion(ratingQuestions[0].id);
    }
  }, [multipleChoiceQuestions, ratingQuestions]);

  // Prepare chart data
  const multipleChoiceChartData = useMemo(() => {
    if (!selectedMultipleChoiceQuestion) return null;
    const mcData = aggregateMultipleChoice(selectedMultipleChoiceQuestion);
    return {
      labels: mcData.labels,
      datasets: [
        {
          data: mcData.data,
          backgroundColor: [
            "#3f51b5",
            "#ff5722",
            "#4caf50",
            "#ffeb3b",
            "#673ab7",
            "#009688",
            "#795548",
          ],
          borderWidth: 1,
        },
      ],
    };
  }, [selectedMultipleChoiceQuestion, aggregateMultipleChoice]);

  const ratingBarData = useMemo(() => {
    if (!selectedRatingQuestion) return null;
    const rData = aggregateRatings(selectedRatingQuestion);
    return {
      labels: rData.labels,
      datasets: [
        {
          label: "Rating Count",
          data: rData.data,
          backgroundColor: [
            "#3f51b5",
            "#ff5722",
            "#4caf50",
            "#ffeb3b",
            "#673ab7",
          ],
        },
      ],
    };
  }, [selectedRatingQuestion, aggregateRatings]);

  // Chart options
  const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Rating",
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
        },
      },
    },
  };

  // If no survey data, return null
  if (!surveyData || surveyData.length === 0) {
    return <Typography>No survey data available</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        <strong>Survey Analysis: </strong>
        {surveyData[0].title}
      </Typography>

      <Grid container spacing={3}>
        {/* Multiple Choice Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
              Multiple Choice Responses
            </Typography>

            {multipleChoiceQuestions?.length > 0 ? (
              <>
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <InputLabel>Select Question</InputLabel>
                  <Select
                    value={selectedMultipleChoiceQuestion || ""}
                    label="Select Question"
                    onChange={(e) =>
                      setSelectedMultipleChoiceQuestion(e.target.value)
                    }
                  >
                    {multipleChoiceQuestions.map((q) => (
                      <MenuItem key={q.id} value={q.id}>
                        {q.question}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {multipleChoiceChartData && (
                  <Box
                    sx={{ height: 300 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Pie
                      data={multipleChoiceChartData}
                      options={pieChartOptions}
                    />
                  </Box>
                )}
              </>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No multiple-choice questions available
              </Typography>
            )}
          </Paper>
        </Grid>

        {/* Rating Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
              Rating Responses
            </Typography>

            {ratingQuestions?.length > 0 ? (
              <>
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <InputLabel>Select Question</InputLabel>
                  <Select
                    value={selectedRatingQuestion || ""}
                    label="Select Question"
                    onChange={(e) => setSelectedRatingQuestion(e.target.value)}
                  >
                    {ratingQuestions.map((q) => (
                      <MenuItem key={q.id} value={q.id}>
                        {q.question}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {ratingBarData && (
                  <Box sx={{ height: 300 }}>
                    <Bar data={ratingBarData} options={barChartOptions} />
                  </Box>
                )}
              </>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No rating questions available
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SurveyAnalysis;
