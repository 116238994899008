import { Alert, CircularProgress, Fade, Snackbar } from '@mui/material'
import { useState } from 'react'

const Toast = ({ setToast = () => {}, isLoading, toast, toastType, toastMessage }) => {
  const [open, setOpen] = useState(toast)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    setToast(false)
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={6000}
      TransitionComponent={Fade}
      onClose={handleClose}
    >
      {isLoading ? (
        <Alert
          icon={<CircularProgress size={18} />}
          variant="filled"
          onClose={handleClose}
          severity={toastType}
          sx={{ width: '100%' }}
        >
          {toastMessage}
        </Alert>
      ) : (
        <Alert variant="filled" onClose={handleClose} severity={toastType} sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      )}
    </Snackbar>
  )
}

export default Toast
