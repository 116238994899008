import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'

export const TableActions = ({
  id,
  actionMenuOptions,
  handleActions,
  isOpen,
  menuAnchorEl,
  handleActionClose = () => {},
}) => {
  const [anchorEl, setAnchorEl] = React.useState(0)
  const [open, setOpen] = React.useState(false)

  const ITEM_HEIGHT = 48
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
    handleActionClose()
  }

  return (
    <div style={{ alignItems: 'baseline', marginTop: '0.4rem' }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          e.stopPropagation()
          handleClick(e)
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        // onClose={handleClose}
        onClose={(e) => {
          e.stopPropagation()
          handleClose()
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {actionMenuOptions.map((option) => (
          <MenuItem
            key={option}
            selected={option === 'Pyxis'}
            onClick={() => {
              handleActions(option, id, setOpen, open)
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
