import React, { useEffect, useState } from "react";
import { Alert, Button, Paper, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import Toast from "../Tost";
import { useFormik } from "formik";
import * as Yup from "yup";

const EmailVerification = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.number().required("OTP is required"),
    }),
    onSubmit: async (values, helpers) => {
      setLoading(true);
      setToast(true);
      if (values.otp) {
        navigate("/company-onboarding");
      }
    },
  });

  useEffect(() => {
    if (otp.length === 6) {
      formik.setFieldValue("otp", otp);
      formik.setFieldError("otp", "");
    } else {
      formik.setFieldError("otp", "Please Enter a Valid 6 digit Code");
    }
  }, [otp]);

  return (
    <>
      <Container maxWidth="sm">
        <Box sx={{ mt: 6, mb: 4 }}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography variant="h4" textAlign={"center"} gutterBottom>
                Please verify your email
              </Typography>
              <Typography color="text.secondary" variant="body2">
                Already have an account? &nbsp;
                <Link to="/login" underline="hover" variant="subtitle2">
                  Sign In
                </Link>
              </Typography>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="body2">
                Please check your email. We have sent you one time password
                (OTP). Please enter that OTP here to verify your email and click
                continue.
              </Typography>
              {formik.errors.submit ||
                (formik.errors.opt && (
                  <Alert severity="error">
                    <Typography
                      // color="error"
                      sx={{ mt: 3 }}
                      variant="body2"
                    >
                      {formik.errors.submit || formik.errors.opt}
                    </Typography>
                  </Alert>
                ))}
            </Stack>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <OTPInput
                  numInputs={6}
                  value={otp}
                  onChange={setOtp}
                  inputType="number"
                  containerStyle={{
                    width: "100% ",
                    justifyContent: "space-around",
                    marginTop: "3rem",
                  }}
                  inputStyle={{
                    width: "4rem",
                    height: "4rem",
                    borderRadius: "4px",
                    border: "1px solid rgba(0,0,0,0.3)",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                  renderInput={(props) => <input required {...props} />}
                />
              </Stack>
              {formik.errors.submit ||
                (formik.errors.opt && (
                  <Typography color="error" sx={{ mt: 3 }} variant="body2">
                    {formik.errors.submit || formik.errors.opt}
                  </Typography>
                ))}
              <div
                style={{
                  marginTop: "3rem",
                  textAlign: "center",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  onClick={() => setOtp("")}
                  style={{ marginRight: "2rem" }}
                  variant="outlined"
                >
                  Clear
                </Button>
                <Button variant="contained" type="submit">
                  Verify
                </Button>
              </div>
            </form>
          </Paper>
        </Box>
      </Container>
      {loading ||
        (toast && (
          <Toast
            isLoading={loading}
            toast={toast}
            toastType={toastType}
            toastMessage={toastMessage}
          />
        ))}
    </>
  );
};

export default EmailVerification;
