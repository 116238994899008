import React from "react";
import { Container } from "@mui/material";
import MySurveys from "../components/survey/MySurveys";

const SurveyPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <MySurveys />
    </Container>
  );
};

export default SurveyPage;
